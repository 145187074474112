import { api } from "boot/axios";
import {
  createInstantDiscount,
  flattenDiscount,
  formatDiscount,
  formatDiscountStatus,
} from "./serializers/discountSerializer";

const apiPrefix = "api/v2";
const discountPrefix = "discounts";

const DiscountAPI = {
  // *** Discounts ***

  async getDiscounts(query, pagination) {
    let reqBody = {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        order: JSON.stringify(pagination.order),
        ...(query.searchQuery && { title: query.searchQuery }),
        is_instant: false,
      },
    };
    const response = await api.get(`${apiPrefix}/${discountPrefix}`, reqBody);
    const result = response.data;
    return result;
  },

  async addDiscount(discount) {
    const { startsAt, endsAt, ...payload } = flattenDiscount(discount);

    const response = await api.post(`${apiPrefix}/${discountPrefix}`, {
      ...payload,
      startsAt: `${startsAt}`,
      endsAt: `${endsAt}`,
    });

    return response.data;
  },

  async addInstantDiscount(discount) {
    const payload = createInstantDiscount(discount);
    const response = await api.post(`${apiPrefix}/${discountPrefix}`, payload);

    return response.data;
  },

  async getDiscount(id) {
    const response = await api.get(`${apiPrefix}/${discountPrefix}/${id}`, {
      params: {
        includes: JSON.stringify([{ relation: "customers" }, { relation: "discount_items" }]),
      },
    });

    const data = response.data.data;
    const payload = formatDiscount(data);

    return payload;
  },

  async editDiscount(id, discount) {
    const payload = flattenDiscount(discount);

    const response = await api.put(`${apiPrefix}/${discountPrefix}/${id}`, payload, {
      params: {
        includes: JSON.stringify([{ relation: "products" }, { relation: "collections" }, { relation: "customers" }]),
      },
    });

    const data = response.data.data;
    return formatDiscount(data);
  },

  async editDiscountStatus(id, status) {
    const payload = formatDiscountStatus(status);

    const response = await api.put(`${apiPrefix}/${discountPrefix}/${id}`, payload);

    const data = response.data.data;
    return formatDiscount(data);
  },

  async deleteDiscount(id) {
    const response = await api.delete(`${apiPrefix}/${discountPrefix}/${id}`);
    return response.data;
  },

  async bulkUpdate(ids, status) {
    const response = await api.put(`${apiPrefix}/${discountPrefix}/update_all`, {
      ids,
      ...status,
    });

    return response.data.success;
  },

  async bulkDelete(ids) {
    const response = await api.delete(`${apiPrefix}/${discountPrefix}/delete_all`, { data: ids });

    return response.data.success;
  },
};

export default DiscountAPI;
