import { useQuery } from "@tanstack/vue-query";
import AuthAPI from "src/api/Auth";

export function useCurrentUser() {
  return useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const response = await AuthAPI.getCurrentUser();
      return response;
    },
    select: (response) => response.data,
  });
}
