import AuthAPI from "src/api/Auth";
import CompanyAPI from "src/api/Company";
import { getPhoneString } from "src/utils";

export async function switchMethod({ commit }, payload) {
  commit("updateMethod", payload);
}

export async function socialAuth({ commit }, payload) {
  commit("logInUser", payload);
}

export async function signUp({ commit }, payload) {
  commit("updateSignUpRequestLoading", true);

  const user = {
    email: payload.email.toLowerCase(),
    name: payload.name,
    password: payload.password,
    loginPhoneNumber: payload.phoneNumber,
    phoneNumber: payload.phoneNumber,
  };

  try {
    const response = await AuthAPI.signUp({ user });
    commit("logInUser", { token: response.accessToken, user: response.user });
    return response;
  } finally {
    commit("updateSignUpRequestLoading", false);
  }
}

export async function startPhoneAuth({ commit }, payload) {
  const strippedPayload = {
    ...payload,
    number: payload.number.replace(/^0/, ""), // removes zero at the start if found
  };

  commit("updatePhone", strippedPayload);
  commit("updateHasAccountRequestLoading", true);

  try {
    const response = await AuthAPI.hasAccount({
      loginPhoneNumber: getPhoneString(strippedPayload),
    });
    commit("updateUser", { name: response.name });
    commit("updateMethod", response.socialProvider || "phone");
    return response;
  } finally {
    commit("updateHasAccountRequestLoading", false);
  }
}

export async function requestOTP({ commit }, payload) {
  commit("updateOtpRequestLoading", true);
  try {
    return await AuthAPI.requestOTP({
      phoneNumber: getPhoneString(payload),
    });
  } finally {
    commit("updateOtpRequestLoading", false);
  }
}

export async function finishPhoneAuth({ commit, state }, payload) {
  commit("updateSignUpRequestLoading", true);

  try {
    const response = await AuthAPI.phoneLogin({
      phoneNumber: getPhoneString(state.phone),
      otp: payload,
    });
    commit("logInUser", { token: response.accessToken, user: response.user });
    if (response.user?.companyId) {
      const company = await CompanyAPI.getCompanyDetails();
      commit("updateCompany", company);
    }
    return response;
  } finally {
    commit("updateSignUpRequestLoading", false);
  }
}

export async function startEmailAuth({ commit }, payload) {
  commit("updateEmail", payload);
  commit("updateHasAccountRequestLoading", true);

  try {
    const response = await AuthAPI.hasAccount({
      email: payload.toLowerCase(),
    });
    commit("updateUser", { name: response.name });
    commit("updateMethod", response.socialProvider || "email");
    return response;
  } finally {
    commit("updateHasAccountRequestLoading", false);
  }
}

export async function finishEmailAuth({ commit, state }, payload) {
  commit("updateSignInRequestLoading", true);

  try {
    const response = await AuthAPI.emailLogin({
      email: state.email.toLowerCase(),
      password: payload,
    });
    commit("logInUser", { token: response.accessToken, user: response.user });
    if (response.user?.companyId) {
      const company = await CompanyAPI.getCompanyDetails();
      commit("updateCompany", company);
    }
    return response;
  } finally {
    commit("updateSignInRequestLoading", false);
  }
}

export async function addInfo({ commit }, payload) {
  commit("updateEditInfoRequestLoading", true);

  try {
    const response = await AuthAPI.updateUser(payload);
    commit("updateUser", response);
    return response;
  } finally {
    commit("updateEditInfoRequestLoading", false);
  }
}

export async function forgotPassword({ commit }, payload) {
  commit("updateForgotPasswordEmail", payload);
  commit("updateForgotPasswordLoading", true);

  try {
    const response = await AuthAPI.forgotPassword(payload);
    return response;
  } finally {
    commit("updateForgotPasswordLoading", false);
  }
}

export async function updatePassword({ commit }, payload) {
  commit("updateForgotPasswordLoading", true);

  try {
    const response = await AuthAPI.updatePassword(payload);
    return response;
  } finally {
    commit("updateForgotPasswordLoading", false);
  }
}

export async function fetchCompanyDetails({ commit, state }, view) {
  const company = await CompanyAPI.getCompanyDetails(view);
  commit("updateCompany", company);

  posthog.identify(state.user.id.toString(), {
    email: state.user.email,
    name: state.user.name,
    company: company.name,
    company_id: company.id,
  });
}

export async function updateCompany({ commit }, payload) {
  commit("updateCompanyLoading", true);

  try {
    const company = await CompanyAPI.updateCompany(payload);
    commit("updateCompany", company);
  } finally {
    commit("updateCompanyLoading", false);
  }
}

export async function signOut({ commit }) {
  await AuthAPI.signOut();
  commit("signOut");

  posthog.reset();
}
