import { api } from "boot/axios";
import { Notify } from "quasar";
import { convertFromCentsByKeys, downloadFile } from "src/utils";

const apiPrefix = "api/v2";
const variantsPrefix = "variants";

const VariantAPI = {
  async getVariants(query, pagination) {
    const response = await api.get(`${apiPrefix}/${variantsPrefix}`, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        order: JSON.stringify(pagination.order),
        ...(query.searchQuery && { search: query.searchQuery }),
      },
    });

    let { variants, metadata } = response.data.data;
    variants = variants.map((variant) => {
      return {
        ...variant,
        ...convertFromCentsByKeys(variant, ["price", "discountedPrice"]),
      };
    });

    return { variants, metadata };
  },

  async updateVariant(id, data) {
    const payload = {
      ...data,
    };

    const response = await api.patch(`${apiPrefix}/${variantsPrefix}/${id}`, payload);

    const result = response.data.data;

    const formattedResult = {
      ...result,
      ...convertFromCentsByKeys(result, ["price", "discountedPrice"]),
    };
    return formattedResult;
  },

  async importVariants(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.post(`${apiPrefix}/${variantsPrefix}/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data.success;
  },

  async exportVariants({ name, limit }) {
    const response = await api.get(`${apiPrefix}/${variantsPrefix}/export`, {
      responseType: "blob",
      params: {
        page: 1,
        limit,
      },
    });

    if (response.headers["content-type"].includes("application/json")) {
      const message = JSON.parse(await response.data.text()).message;
      Notify.create({ message });
    } else {
      downloadFile(response.data, `${name}.xlsx`);
    }
  },
};

export default VariantAPI;
