import { api } from "boot/axios";

const apiPrefix = "api/v2";
const productsPrefix = "products";

const InstagramAPI = {
  async getPosts(token) {
    const response = await api.get(`${apiPrefix}/${productsPrefix}/get_instagram_posts`, { params: { token } });
    return response.data.data;
  },

  async createProducts(products) {
    await api.post(`${apiPrefix}/${productsPrefix}/import_instagram_posts`, {
      posts: products,
    });
  },
};

export default InstagramAPI;
