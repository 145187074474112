export function updateCompanyLoading(state, loading) {
  state.loading = loading;
}

export function fetchCompanyDetailsLoading(state, loading) {
  state.loading = loading;
}

export function fetchCompanyDetails(state, response) {
  state.data = response;
}

export function updateCompanyStoreFeatures(state, response) {
  state.data.company.storeFeatures = response;
}

export function updateCompanyPlatforms(state, response) {
  state.data.company.companyLinks = response;
}

export function fetchPlatforms(state, response) {
  state.platforms.data = response;
}

export function fetchPlatformsLoading(state, loading) {
  state.platforms.loading = loading;
}

export function fetchStoreFeatures(state, response) {
  state.storeFeatures.data = response;
}

export function fetchStoreFeaturesLoading(state, loading) {
  state.storeFeatures.loading = loading;
}
