export default function () {
  return {
    currentCollections: {
      loading: false,
      data: [],
      metadata: {
        totalCount: 0,
      },
    },
    newCollection: {
      loading: false,

      basicInfo: {
        name: {
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
      },
      type: "manual",
      conditions: {
        operator: "or",
        values: [{ parameter: "name", operator: "matches", value: "" }],
      },
      products: [],
      sort: "created_at_desc",
      media: null,
    },
    collectionDetails: {
      collection: null,
      products: [],
      metadata: {
        totalCount: 0,
      },

      loading: false,
      productsLoading: false,
    },
  };
}
