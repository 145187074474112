export default function () {
  return {
    store: {
      loading: false,

      name: "",
      subdomain: "",
      email: "",
      industryId: null,
      sellingState: "",
      currentlySelling: [],
      address: {
        addressLine: "",
        apartment: "",
        floor: "",
        building: "",
        governerate: "",
        area: "",
      },
    },

    industries: {
      data: [],
      loading: false,
    },

    checkSubdomainLoading: false,
  };
}
