import DiscountAPI from "src/api/Discount";

export async function getCurrentDiscounts({ commit }, { payload, append }) {
  if (!append) commit("updateCurrentDiscountsLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order || { id: "desc" },
  };
  const query = {
    searchQuery: payload.searchQuery || null,
  };

  try {
    let result = await DiscountAPI.getDiscounts(query, pagination);
    if (append) commit("addDiscounts", result);
    else commit("updateCurrentDiscountsList", result);
  } finally {
    if (!append) commit("updateCurrentDiscountsLoading", false);
  }
}

export async function addDiscount({ commit }, payload) {
  commit("updateAddDiscountLoading", true);
  try {
    const result = await DiscountAPI.addDiscount(payload);
    return result;
  } finally {
    commit("updateAddDiscountLoading", false);
  }
}

export async function addInstantDiscount({ commit }, payload) {
  commit("updateAddDiscountLoading", true);
  try {
    const result = await DiscountAPI.addInstantDiscount(payload);
    return result;
  } finally {
    commit("updateAddDiscountLoading", false);
  }
}

export async function getDiscount({ commit }, id) {
  commit("resetDiscountDetails");
  commit("updateDiscountLoading", true);

  try {
    const discount = await DiscountAPI.getDiscount(id);
    commit("updateDiscountDetails", discount);
    return discount;
  } finally {
    commit("updateDiscountLoading", false);
  }
}

export async function editDiscount({ commit }, payload) {
  commit("updateDiscountLoading", true);

  try {
    const discount = await DiscountAPI.editDiscount(payload.id, payload.discount);

    commit("updateDiscountDetails", discount);
    return discount;
  } finally {
    commit("updateDiscountLoading", false);
  }
}

export async function editDiscountStatus({ commit }, payload) {
  commit("updateDiscountLoading", true);

  try {
    const discount = await DiscountAPI.editDiscountStatus(payload.id, payload.status);

    commit("updateDiscountStatus", discount);
    return discount;
  } finally {
    commit("updateDiscountLoading", false);
  }
}

export async function deleteDiscount({ commit }, id) {
  commit("updateDiscountLoading", true);

  try {
    const response = await DiscountAPI.deleteDiscount(id);
    return response;
  } finally {
    commit("updateDiscountLoading", false);
  }
}

export async function bulkUpdate({ commit }, { ids, data }) {
  commit("updateDiscountLoading", true);
  try {
    const response = await DiscountAPI.bulkUpdate(ids, data);
    commit("updateDiscountDetails", response);
    return response;
  } finally {
    commit("updateDiscountLoading", false);
  }
}

export async function bulkDelete({ commit }, ids) {
  commit("updateDiscountLoading", true);
  try {
    const response = await DiscountAPI.bulkDelete(ids);
    return response;
  } finally {
    commit("updateDiscountLoading", false);
  }
}
