import { boot } from "quasar/wrappers";
import axios from "axios";
import { handleErrors } from "src/utils/errorUtils";
import { apiHost } from "src/utils/constants";

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

const api = axios.create({
  baseURL: apiHost,
  withCredentials: true,
});

export default boot(({ app, store, router }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  api.interceptors.request.use((config) => {
    // if locale is set to "all", do not add a header,
    // the BE will return all available translations
    if (config.headers.locale !== "all") {
      // Prioritize the locale header if set, then the current locale from the route, then english
      const locale = config.headers.locale || router.currentRoute.value.params.locale || "en";
      config.headers.locale = locale;
    } else {
      delete config.headers.locale;
    }

    // add authToken to all requests
    const token = store.state.auth.authToken;
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  api.interceptors.response.use(null, (error) => {
    const handled = handleErrors(error, router);
    error.response = {
      ...error.response,
      handled,
    };
    return Promise.reject(error);
  });

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
