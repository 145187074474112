import { api } from "boot/axios";
import { format } from "date-fns";

const apiPrefix = "api/v2";

const ZammitPartnerAPI = {
  async getZammitPartnerCategories() {
    const response = await api.get(`${apiPrefix}/zammit_partner_categories`);
    return response.data;
  },
  async getZammitPartners() {
    const response = await api.get(`${apiPrefix}/zammit_partners`);
    return response.data;
  },
};

export default ZammitPartnerAPI;
