const egypt = {
  phone: "20",
  emoji: "🇪🇬",
};

export default function () {
  return {
    newAddressLoading: false,
    newCustomer: {
      loading: false,
      error: null,
      basicInfo: {
        firstName: "",
        lastName: "",
        email: "",
        phone: {
          number: "",
          country: egypt,
        },
      },
      addressInfo: {
        city: "",
        zone: "",
        district: "",
        neighbourhood: "",
        country: "",
        line: "",
        building: "",
        floor: "",
        apartment: "",
        locationId: null,
      },
    },
    currentCustomers: {
      loading: false,
      data: [],
      metadata: {
        totalCount: 0,
        totalPages: 0,
      },
    },
    customerDetails: {
      loading: false,
      error: null,
      data: null,
    },
    spendingRange: {
      loading: false,
      range: {
        min: 0,
        max: 0,
      },
    },
    markAsDefaultLoading: false,
    exportLoading: false,
  };
}
