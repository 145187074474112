export default function () {
  return {
    authToken: null,
    user: null,
    permissions: [],
    company: null,

    method: "email",
    email: null,
    phone: null,

    forgotPassword: {
      email: "",
      loading: false,
    },

    otpRequest: {
      error: null,
      loading: false,
    },
    signUpRequest: {
      error: null,
      loading: false,
    },
    signInRequest: {
      error: null,
      loading: false,
    },
    hasAccountRequest: {
      error: null,
      loading: false,
    },
    editInfoRequest: {
      error: null,
      loading: false,
    },

    companyLoading: false,
  };
}
