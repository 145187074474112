export function updateCurrentCollectionsLoading(state, response) {
  state.currentCollections.loading = response;
}

export function updateCurrentCollectionsList(state, response) {
  state.currentCollections.data = response.data;
  state.currentCollections.metadata = response.metadata;
}

export function addCollections(state, response) {
  state.currentCollections.data.push(...response.data);
  state.currentCollections.metadata = response.metadata;
}

export function updateAddCollectionLoading(state, isLoading) {
  state.newCollection.loading = isLoading;
}

export function updateCollectionLoading(state, isLoading) {
  state.collectionDetails.loading = isLoading;
}

export function updateCollectionDetails(state, collection) {
  state.collectionDetails.collection = collection;
}

export function resetCollectionDetails(state) {
  state.collectionDetails = {
    collection: null,
    products: [],
    metadata: {
      totalCount: 0,
    },

    loading: false,
    productsLoading: false,
  };
}

export function updateCollectionProducts(state, data) {
  if (data.products) state.collectionDetails.products = data.products;
  if (data.metadata) state.collectionDetails.metadata = data.metadata;
}

export function pushCollectionProducts(state, data) {
  state.collectionDetails.products.push(...data.products);
}

export function updateCollectionProductsLoading(state, isLoading) {
  state.collectionDetails.productsLoading = isLoading;
}
