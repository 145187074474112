export function updatePartnerCategories(state, response) {
  state.zammitPartnerCategories.data = response;
}

export function updatePartnerCategoriesLoadingState(state, value) {
  state.zammitPartnerCategories.loading = value;
}
export function updateZammitPartnersLoadingState(state, value) {
  state.zammitPartners.loading = value;
}
export function updateZammitPartners(state, response) {
  state.zammitPartners.data = response;
}
