import { formatValueFromAPI, prepareValueToAPI } from "src/pages/Dashboard/Collections/utils";

import { flattenObject, localizeObject } from "src/utils";

const flattenBasicInfo = (collection) => {
  return {
    ...flattenObject(collection, "name", ""),
    ...flattenObject(collection, "description", ""),
    handle: collection.handle,
  };
};

const formatBasicInfo = (collection) => {
  return {
    ...localizeObject(collection, "name", ""),
    ...localizeObject(collection, "description", ""),
    handle: collection.handle,
  };
};

export const flattenCollection = (collection) => {
  let payload = {
    ...flattenBasicInfo(collection.basicInfo),
    imageId: collection.media?.id,
    ...getSortingObject(collection.sort),
  };

  if (collection.type === "manual" && collection.products) {
    payload = {
      ...payload,
      products: collection.products,
    };
  } else if (collection.type === "smart") {
    payload = {
      ...payload,
      conditions: {
        operator: collection.conditions.operator,
        values: collection.conditions.values.map((condition) => {
          return {
            parameter: condition.parameter,
            operator: condition.operator,
            value: prepareValueToAPI(condition),
          };
        }),
      },
    };
  }

  return payload;
};

export const formatCollection = (collection) => {
  let payload = {
    id: collection.id,
    basicInfo: formatBasicInfo(collection),
    media: collection.image ? { ...collection.image, progress: 1 } : null,
    type: collection.isSmart ? "smart" : "manual",
    sort: getSortingKey(collection),
  };

  if (!collection.isSmart) {
    payload = {
      ...payload,
      products: collection.products,
    };
  } else {
    payload = {
      ...payload,
      conditions: {
        operator: collection.conditions.operator,
        values: collection.conditions.values.map((condition) => {
          return {
            parameter: condition.parameter,
            operator: condition.operator,
            value: formatValueFromAPI(condition),
            isAllProducts: condition.parameter === "id",
          };
        }),
      },
    };
  }
  return payload;
};

export const formatProducts = (collectionProducts) =>
  collectionProducts.map((collectionProduct, index) => {
    return { productId: collectionProduct.productId, order: index };
  });

const sortingMap = {
  name_asc: { sort: "product_translations.name", sortDirection: "ASC" },
  name_desc: { sort: "product_translations.name", sortDirection: "DESC" },
  price_asc: { sort: "products.price_cents", sortDirection: "ASC" },
  price_desc: { sort: "products.price_cents", sortDirection: "DESC" },
  created_at_asc: { sort: "products.created_at", sortDirection: "ASC" },
  created_at_desc: { sort: "products.created_at", sortDirection: "DESC" },
  manual: { sort: "collection_products.order", sortDirection: "ASC" },
};

const defaultSorting = "manual";

const getSortingObject = (sortString) => {
  const object = sortingMap[sortString];

  return object || sortingMap[defaultSorting];
};

const getSortingKey = (sortObject) => {
  const index = Object.values(sortingMap).findIndex(
    (object) => sortObject?.sort === object.sort && sortObject?.sortDirection === object.sortDirection
  );

  if (index === -1) return defaultSorting;
  return Object.keys(sortingMap)[index];
};
