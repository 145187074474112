import { api } from "boot/axios";
import { flattenCollection, formatCollection, formatProducts } from "./serializers/collectionSerializer";

const apiPrefix = "api/v2";
const collectionPrefix = "collections";

const CollectionAPI = {
  // *** Collections ***

  async getCollections(query, pagination, isSmart) {
    let reqBody = {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        order: JSON.stringify(pagination.order),
        ...(query.searchQuery && { search: query.searchQuery }),
        ...(isSmart !== null && { is_smart: isSmart }),
      },
    };
    const response = await api.get(`${apiPrefix}/${collectionPrefix}`, reqBody);

    const result = response.data;
    return result;
  },

  async addCollection(collection) {
    const payload = flattenCollection(collection);
    const response = await api.post(`${apiPrefix}/${collectionPrefix}`, payload);

    return response.data;
  },

  async getCollection(id) {
    const response = await api.get(`${apiPrefix}/${collectionPrefix}/${id}`, {
      headers: {
        locale: "all",
      },
    });

    const data = response.data.data;
    const payload = formatCollection(data);
    return payload;
  },

  async editCollection(id, collection) {
    const payload = flattenCollection(collection);

    const response = await api.put(`${apiPrefix}/${collectionPrefix}/${id}`, payload, {
      headers: {
        locale: "all",
      },
    });

    const data = response.data.data;
    return formatCollection(data);
  },

  async deleteCollection(id) {
    const response = await api.delete(`${apiPrefix}/${collectionPrefix}/${id}`);
    return response.data;
  },

  async deleteCollectionImage(id) {
    const response = await api.delete(`${apiPrefix}/${collectionPrefix}/${id}/image`, {
      headers: {
        locale: "all",
      },
    });

    const data = response.data.data;
    return formatCollection(data);
  },

  // *** Collection Products ***

  async addProducts(collectionId, products) {
    const response = await api.post(`${apiPrefix}/${collectionPrefix}/${collectionId}/products`, {
      products: formatProducts(products),
    });

    const data = response.data.data;
    const payload = formatCollection(data);
    return payload;
  },

  async getProducts(id, pagination) {
    const response = await api.get(`${apiPrefix}/${collectionPrefix}/${id}/products`, { params: { ...pagination } });

    const data = response.data.data;
    return data;
  },

  async orderProducts(id, products) {
    const response = await api.patch(`${apiPrefix}/${collectionPrefix}/${id}/products/update_all`, { products });

    const status = response.data.status;
    return status;
  },

  async deleteProduct(collectionId, id) {
    const response = await api.delete(`${apiPrefix}/${collectionPrefix}/${collectionId}/products/${id}`);
    return response.data;
  },
};

export default CollectionAPI;
