export function updateOrdersLoading(state, loading) {
  state.loading = loading;
}

export function updateOrdersList(state, response) {
  state.currentOrders.orderList = response.orders;
  state.currentOrders.metadata = response.metadata;
}

export function addOrders(state, response) {
  state.currentOrders.orderList.push(...response.orders);
  state.currentOrders.metadata = response.metadata;
}

export function updateDraftOrdersList(state, response) {
  state.draftOrders.orderList = response.orders;
  state.draftOrders.metadata = response.metadata;
}

export function addDraftOrders(state, response) {
  state.draftOrders.orderList.push(...response.orders);
  state.draftOrders.metadata = response.metadata;
}

export function updateMetadataLoading(state, loading) {
  state.metadata.loading = loading;
}

export function updateMetadata(state, response) {
  state.metadata.data = response.metadata;
}

export function updateOrderDetailsLoading(state, loading) {
  state.orderDetails.loading = loading;
}
export function updateDraftOrderLoading(state, loading) {
  state.newOrder.loading = loading;
}

export function updateOrderDetails(state, response) {
  state.orderDetails = {
    ...state.orderDetails,
    data: response,
  };
}

export function resetOrderDetails(state) {
  state.orderDetails = {
    loading: false,
    error: null,
    data: null,
  };
}

export function updateExportLoading(state, loading) {
  state.exportLoading = loading;
}

export function updateCreateDraftOrder(state, response) {
  state.newOrder = {
    ...state.newOrder,
    data: response.purchase,
  };
}

export function updateOrder(state, response) {
  state.newOrder = {
    ...state.newOrder,
    data: response,
    products: response.purchaseProducts,
  };
}

export function updateCreateDraftOrderLoading(state, loading) {
  state.newOrder.loading = loading;
}
