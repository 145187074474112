export default function () {
  return {
    zammitPartnerCategories: {
      loading: false,
      data: [],
    },
    zammitPartners: {
      loading: false,
      data: [],
    },
  };
}
