import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import close from "assets/icons/close.svg";
import rightChevron from "assets/icons/right-chevron.svg";
import leftChevron from "assets/icons/left-chevron.svg";
import deleteOutlined from "assets/icons/delete-outlined.svg";
import greyTick from "assets/icons/grey-tick.svg";
import greenTick from "assets/icons/green-tick.svg";
import redTick from "assets/icons/red-tick.svg";
import purpleTick from "assets/icons/purple-tick.svg";
import user from "assets/icons/user.svg";
import edit from "assets/icons/edit.svg";
import addCollection from "assets/icons/add-collection.svg";
import deleteCollection from "assets/icons/delete-collection.svg";
import emailSent from "assets/icons/email-sent.svg";
import editOutlined from "assets/icons/edit-outlined.svg";
import outOfStock from "assets/icons/out-of-stock.svg";
import headset from "assets/icons/headset.svg";
import manualCollection from "assets/icons/manual-collection.svg";
import smartCollection from "assets/icons/smart-collection.svg";
import emptyBox from "assets/icons/empty-box.svg";
import returns from "assets/icons/returns.svg";
import failed from "assets/icons/failed.svg";
import cancelled from "assets/icons/cancelled.svg";
import exit from "assets/icons/exit.svg";
import billing from "assets/icons/billing.svg";

import productHand from "../assets/icons/product-hand.svg";
import noWifi from "../assets/icons/no-wifi.svg";
import wifi from "../assets/icons/wifi.svg";
import italic from "../assets/icons/toolbar/italic.svg";
import ordered from "../assets/icons/toolbar/ordered.svg";
import back from "../assets/icons/back.svg";
import noContent from "../assets/icons/no-content.svg";
import selected from "../assets/icons/selected.svg";
import add from "../assets/icons/add.svg";
import subtract from "../assets/icons/subtract.svg";
import collect from "../assets/icons/collect.svg";
import error from "../assets/icons/error-page.svg";
import discount from "../assets/icons/discount.svg";
import abstractProduct from "assets/icons/abstract-product.svg";
import truck from "assets/icons/truck.svg";
import creditCard from "assets/icons/credit-card.svg";
import wwwGlobe from "assets/icons/www-globe.svg";
import webPage from "assets/icons/web-page.svg";
import compassNeedle from "assets/icons/compass-needle.svg";
import disable from "assets/icons/disable.svg";
import paymentGateway from "assets/icons/partners/secure-payment.svg";
import shipping from "assets/icons/partners/shipping.svg";
import warehouse from "assets/icons/partners/warehouse.svg";
import socialMedia from "assets/icons/social-media.svg";
import system from "assets/icons/partners/system.svg";
import photography from "assets/icons/partners/photography.svg";
import cashBack from "assets/icons/partners/cash-back.svg";
import customerLoyalty from "assets/icons/partners/customer-loyalty.svg";
import verified from "assets/icons/partners/verified.svg";
import instagram from "assets/icons/instagram.svg";
import linkedin from "assets/icons/linkedin.svg";
import partner from "assets/icons/partner.svg";
import win from "assets/icons/win.svg";
import egypt from "assets/icons/egypt.svg";
import uk from "assets/icons/united-kingdom.svg";
import list from "assets/icons/list.svg";
import factory from "assets/icons/factory.svg";
import healthcare from "assets/icons/healthcare.png";
import printOnDemand from "assets/icons/print-on-demand.png";
import insurance from "assets/icons/insurance.jpeg";
import behance from "assets/icons/behance.png";
import menuThreeDots from "assets/icons/menu-three-dots.svg";
import gift from "assets/icons/gift.svg";
import openPackage from "assets/icons/open-package.svg";

export default {
  // multi-color svgs
  "icon-zam-facebook": `img:${facebook}`,
  "icon-zam-google_oauth2": `img:${google}`,
  "icon-zam-close": `img:${close}`,
  "icon-zam-user": `img:${user}`,
  "icon-zam-email-sent": `img:${emailSent}`,
  "icon-zam-delete-outlined": `img:${deleteOutlined}`,
  "icon-zam-edit-outlined": `img:${editOutlined}`,
  "icon-zam-no-content": `img:${noContent}`,
  "icon-zam-out-of-stock": `img:${outOfStock}`,
  "icon-zam-selected": `img:${selected}`,
  "icon-zam-manual-collection": `img:${manualCollection}`,
  "icon-zam-smart-collection": `img:${smartCollection}`,
  "icon-zam-empty-box": `img:${emptyBox}`,
  "icon-zam-product-hand": `img:${productHand}`,
  "icon-zam-no-wifi": `img:${noWifi}`,
  "icon-zam-wifi": `img:${wifi}`,
  "icon-zam-add": `img:${add}`,
  "icon-zam-subtract": `img:${subtract}`,
  "icon-zam-collect": `img:${collect}`,
  "icon-zam-error": `img:${error}`,
  "icon-zam-discount": `img:${discount}`,
  "icon-zam-gift": `img:${gift}`,
  "icon-zam-returns": `img:${returns}`,
  "icon-zam-failed": `img:${failed}`,
  "icon-zam-cancelled": `img:${cancelled}`,
  "icon-zam-abstract-product": `img:${abstractProduct}`,
  "icon-zam-truck": `img:${truck}`,
  "icon-zam-credit-card": `img:${creditCard}`,
  "icon-zam-www-globe": `img:${wwwGlobe}`,
  "icon-zam-web-page": `img:${webPage}`,
  "icon-zam-compass-needle": `img:${compassNeedle}`,
  "icon-zam-payment-gateway": `img:${paymentGateway}`,
  "icon-zam-coloredShipping": `img:${shipping}`,
  "icon-zam-warehouse": `img:${warehouse}`,
  "icon-zam-social-media": `img:${socialMedia}`,
  "icon-zam-marketing-system": `img:${system}`,
  "icon-zam-photography": `img:${photography}`,
  "icon-zam-cash-back": `img:${cashBack}`,
  "icon-zam-customer-loyalty": `img:${customerLoyalty}`,
  "icon-zam-verified": `img:${verified}`,
  "icon-zam-linkedin": `img:${linkedin}`,
  "icon-zam-instagram": `img:${instagram}`,
  "icon-zam-win": `img:${win}`,
  "icon-zam-english": `img:${uk}`,
  "icon-zam-arabic": `img:${egypt}`,
  "icon-zam-list": `img:${list}`,
  "icon-zam-factory": `img:${factory}`,
  "icon-zam-healthcare": `img:${healthcare}`,
  "icon-zam-print-on-demand": `img:${printOnDemand}`,
  "icon-zam-exit": `img:${exit}`,
  "icon-zam-insurance": `img:${insurance}`,
  "icon-zam-behance": `img:${behance}`,
  "icon-zam-menu-three-dots": `img:${menuThreeDots}`,
  "icon-zam-open-package": `img:${openPackage}`,

  // broken in icomoon
  "icon-zam-right-chevron": `img:${rightChevron}`,
  "icon-zam-left-chevron": `img:${leftChevron}`,
  "icon-zam-grey-tick": `img:${greyTick}`,
  "icon-zam-green-tick": `img:${greenTick}`,
  "icon-zam-red-tick": `img:${redTick}`,
  "icon-zam-purple-tick": `img:${purpleTick}`,
  "icon-zam-italic": `img:${italic}`,
  "icon-zam-insertOrderedList": `img:${ordered}`,
  "icon-zam-back": `img:${back}`,
  "icon-zam-edit": `img:${edit}`,
  "icon-zam-add-collection": `img:${addCollection}`,
  "icon-zam-delete-collection": `img:${deleteCollection}`,
  "icon-zam-headset": `img:${headset}`,
  "icon-zam-disable": `img:${disable}`,
  "icon-zam-partner": `img:${partner}`,
  // "icon-zam-billing": `img:${billing}`,
};
