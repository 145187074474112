import { i18n } from "boot/i18n";
const $t = i18n.global.t;

/*** Constants ***/

const supportedCharts = [
  "sales",
  "orderCount",
  "orderAverage",
  "orderStatus",
  "classifiedSales",
  "salesChannels",
  "salesPeople",
  "paymentMethods",
  "topValue",
  "topUnits",
  "returningCustomers",
];

export const statusColors = {
  ready: "#00b698",
  delivered: "#4f3fa8", //zam-secondary-purple
  failed: "#d47b21", //zam-states-warning
  cancelled: "#df5147",
  returned: "#5d5d69", //zam-greys-text
};

// TODO to BE: remove this and include all statuses for consistency
export const totalSalesStatuses = ["failed", "cancelled", "returned"];

export const defaultConfig = {
  chart: {
    textStyle: {
      fontFamily: "Public Sans",
      fontSize: 12,
      lineHeight: 1.42,
      color: "#5d5d69", //zam-greys-text
    },
    grid: {
      left: "2%",
      top: "10%",
      right: "2%",
      bottom: "10%",
      containLabel: true,
    },
    legend: {
      textStyle: {
        fontFamily: "Public Sans",
        fontSize: 12,
        lineHeight: 1.42,
        color: "#5d5d69", //zam-greys-text
      },
    },
  },
};

/*** Maps ***/

export const paramsMap = {
  sales: {
    endpoint: "sales",
  },
  classifiedSales: {
    endpoint: "sales",
  },
  salesChannels: {
    endpoint: "orders/total_completed",
    groupBy: "channel",
  },
  salesPeople: {
    endpoint: "orders/total_completed",
    groupBy: "creator",
  },
  orderCount: {
    endpoint: "orders",
    type: "count",
  },
  orderAverage: {
    endpoint: "orders",
    type: "average",
  },
  orderStatus: {
    endpoint: "orders_by_status",
  },
  paymentMethods: {
    endpoint: "orders/total_completed",
  },
  topValue: {
    endpoint: "top_products",
    type: "value",
    limit: 10,
  },
  topUnits: {
    endpoint: "top_products",
    type: "unit",
    limit: 10,
  },
  returningCustomers: {
    endpoint: "returning_customers",
  },
};

const chartDataGettersMap = {
  sales: ({ data }) => [
    data.graph_points.map((point, index, array) => [
      point.date_point,
      point.sales,
      point.change_percentage,
      index !== 0 ? array[index - 1].date_point : null,
    ]),
  ],
  orderCount: ({ data }) => [data.graph_points.map((point) => [point.date_point, point.orders_in_period])],
  orderAverage: ({ data }) => [data.graph_points.map((point) => [point.date_point, point.orders_in_period])],
  orderStatus: ({ data }) => [
    data.statuses.map((point) => {
      return { value: point.count, name: point.name };
    }),
  ],
  salesChannels: ({ data }) => [
    data.by_method.map((method) => {
      const name = $t(`charts.sales_channels.types.${method.group}`);
      return { value: method.value, name };
    }),
  ],
  salesPeople: ({ data }) => [
    data.by_method.map((person) => {
      return { value: person.value, name: person.group.name };
    }),
  ],
  classifiedSales: ({ data }) =>
    totalSalesStatuses.map((status) => data.graph_points.map((point) => [point.date_point, point[status] || 0])),
  paymentMethods: ({ data, channel }) => {
    const getPercentage = (value) => ((value / data.total_completed_orders) * 100).toFixed(2);

    const getName = (value) => $t(`charts.payment_methods.types.${channel}.${value}`);

    return [data.by_method.map((point) => [point.value, `${getName(point.group)} (${getPercentage(point.value)}%)`])];
  },
  topValue: ({ data }) =>
    data.products.map((product) => {
      return {
        id: product.id,
        name: product.name,
        value: product.total_value,
        deleted: product.deleted,
      };
    }),
  topUnits: ({ data }) =>
    data.products.map((product) => {
      return {
        id: product.id,
        name: product.name,
        value: product.total_value,
        deleted: product.deleted,
      };
    }),
  returningCustomers: ({ data }) => [
    {
      name: data.returning_users,
      value: data.change_percentage,
      change: data.change_percentage,
    },
  ],
};

/*** Main Functions ***/

export const tooltipOptions = ({ tooltipFn, showTooltip, ...extraParams }) => {
  return {
    show: showTooltip,
    trigger: "item",
    formatter: function (params, _ticket, _callback) {
      // echarts doesn't support custom components for the tooltip
      // so we have to supply raw HTML instead of a vue component
      return tooltipFn({ params, ...extraParams });
    },
  };
};

export const getChartData = ({ data, chart, channel }) => {
  if (!supportedCharts.includes(chart)) return [];

  return chartDataGettersMap[chart]({ data, channel });
};

export const formatChartOptions = ({ data, config }) => {
  const options = {
    ...config.chart,
    series: data.map((series, index) => {
      return {
        ...config.series[index],
        data: series,
      };
    }),
  };

  return options;
};
