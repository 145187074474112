import { i18n } from "boot/i18n";
import { apiHost, billingFrequencySequences } from "./constants";
import { Store } from "src/store";
import { formatPrice } from "./price";

export const getStoreUrl = (company) => {
  const { customUrl, subdomain } = company || {};

  if (customUrl) return customUrl;
  else if (subdomain) return `${subdomain}.myzammit.shop`;
  else return "store.myzammit.shop";
};

export const getSocialMediaLink = (vendor, origin) => {
  let url = `${apiHost}/auth/${vendor}`;
  if (origin) url += `?origin=${origin}`;
  return url;
};

export const getPhoneString = (phoneObj) => {
  if (phoneObj && phoneObj.country && phoneObj.number)
    return `+${phoneObj.country.phone}${phoneObj.number.replace(/^0/, "")}`;
  else return "";
};

export const getCustomerPhoneString = (phoneObj) => {
  if (phoneObj && phoneObj.country && phoneObj.number) {
    if (phoneObj.country.phone === "20") {
      return phoneObj.number.length === 11 ? phoneObj.number : `0${phoneObj.number}`;
    }
    return `+${phoneObj.country.phone}${phoneObj.number.replace(/^0/, "")}`;
  } else return "";
};

export const setUpFileReader = (file) => {
  const reader = new FileReader();

  if (file) {
    reader.readAsDataURL(file);
  }

  return reader;
};

/**
 * Extracts the correct price by assessing whether the product is on sale.
 */
export const getPrice = (pricingObject) => {
  return pricingObject.isOnSale ? pricingObject.discountedPrice : pricingObject.price;
};

export const getVariantsPriceRange = (variants) => {
  const min = variants.reduce((prev, curr) => (prev.priceCents > curr.priceCents ? curr : prev)).priceCents;

  const max = variants.reduce((prev, curr) => (prev.priceCents < curr.priceCents ? curr : prev)).priceCents;

  if (min === max) return formatPrice(min / 100);
  else return `${formatPrice(min / 100)}-${formatPrice(max / 100)}`;
};

/**
 * Returns a new object with the supplied keys (appended with "Cents")
 * The new values are calculated by multiplying the original values by 100
 */
export const convertToCentsByKeys = (object, keys) => {
  let output = {};
  for (const key of keys) {
    output = {
      ...output,
      [`${key}Cents`]: (object[key] || 0) * 100,
    };
  }
  return output;
};

/**
 * Returns a new object with the supplied keys
 * The new values are calculated by dividing the original values
 * (of the supplied keys appended with "Cents") by 100
 */
export const convertFromCentsByKeys = (object, keys) => {
  let output = {};
  for (const key of keys) {
    output = {
      ...output,
      [key]: (object[`${key}Cents`] || 0) / 100,
    };
  }
  return output;
};

export const arrayToSentence = (arr, includeVerb) => {
  const t = i18n.global.t;
  const extraItemsCount = arr.slice(2).length; // indicates  the count of extra items (more than 2)  in the chip
  const lastItem = `$1`; // indicates the first captured group in a string (regex) which is used in replacement string
  const manyItems = `${extraItemsCount} ${t("products.all_products.filters.more")}`;

  const sentenceVerb =
    arr.length === 1 ? `  ${t("products.all_products.filters.is")} ` : `  ${t("products.all_products.filters.are")} `; // indicates which verb should be used according to the count of items

  const body =
    extraItemsCount > 0
      ? `  ${t("products.all_products.filters.and")}` + manyItems
      : `  ${t("products.all_products.filters.and")}` + lastItem; // this body is used to replace the array with either multiple items or last item

  return (includeVerb ? sentenceVerb : "").concat(
    // includeVerb is used to indicate whether verbs are added or array is changed to sentence only
    arr
      .slice(0, 3)
      .join(", ")
      .replace(/,\s([^,]+)$/, body) // the first 2 items in arr are joined together and rest of array is replaced with the body
  );
};

export const hideEmail = (email) => {
  const [id, provider] = email.split("@");
  const suffixLength = id.length > 2 ? 2 : 1;
  return `${id[0]}***${id.slice(-1 * suffixLength)}@${provider}`;
};

export const openAuthWindow = ({ fullPath, origin, windowWidth, vendor, redirect = "auth-success" }) => {
  const originPath = `${origin}/${redirect}`;
  const url = fullPath || getSocialMediaLink(vendor, originPath);

  const options = `menubar=no, toolbar=no, top=150, left=${windowWidth / 2 - 250}, width=500, height=600`;

  window.open(url, "_blank", options);
};

export const localizeObject = (object, key, fallback) => {
  return {
    [key]: {
      en: object[`${key}En`] || fallback,
      ar: object[`${key}Ar`] || fallback,
    },
  };
};

export const flattenObject = (object, key, fallback) => {
  return {
    [`${key}En`]: object[key].en || fallback,
    [`${key}Ar`]: object[key].ar || fallback,
  };
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * Generic file downloader -- Works on all browsers
 */
export const downloadFile = (data, name) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// one liner to sort an array of strings alphanumerically
// thanks to https://stackoverflow.com/a/40355107
export const sortStrings = (array) => array.sort((a, b) => (a > b) - (a < b));

export const authRedirect = ({ user, router, requiredFields = ["name"] }) => {
  // redirect back to v1 for master_admin and super_admin
  if (user?.role === "master_admin") {
    router.push({ name: "railsAdmin" });
    return;
  }

  // check all required fields are set
  if (requiredFields.every((field) => user[field])) {
    if (user?.companyId) {
      // has company, redirect to dashboard
      router.push({ name: "home" });
    } else {
      // has no company, redirect to create company store
      router.push({ name: "storeCreation" });
    }
  } else {
    // Needs to add missing required fields
    router.push({ name: "finishSignup" });
  }
};

export const sortBillingFrequencies = (a, b) => {
  return billingFrequencySequences[a] - billingFrequencySequences[b];
};

export const convertAddressToString = (address) => {
  if (!address) return "";

  let text = "";
  if (address.apartment) text += `${address.apartment}, `;
  if (address.floor) text += `${address.floor}, `;
  if (address.building) text += `${address.building}, `;
  if (address.line) text += `${address.line}, `;
  if (address.district) text += `${address.district.name_en}, `;
  if (address.zone) text += `${address.zone.name_en}, `;
  if (address.city) text += `${address.city.name_en}, `;

  return text.slice(0, -2); //remove the trailing `, `
};

export const convertBuildingDetailsToString = (buildingDetails) => {
  if (!buildingDetails) return "";

  let text = "";
  if (buildingDetails.apartment) text += `${buildingDetails.apartment}, `;
  if (buildingDetails.floor) text += `${buildingDetails.floor}, `;
  if (buildingDetails.building) text += `${buildingDetails.building}, `;

  return text.slice(0, -2);
};

export const sendHelpEmail = () => {
  const options = `menubar=no, toolbar=no, top=150, left=${window.innerWidth / 2 - 600}, width=1200, height=600`;

  window.open("mailto:support@zammit.shop", "_blank", options);
};

export const getArrowStyle = (change) => {
  if (change > 0)
    return {
      color: "zam-primary-green",
      tint: "zam-tints-green",
      icon: "north",
    };

  if (change < 0)
    return {
      color: "zam-states-error",
      tint: "zam-tints-red",
      icon: "south",
    };

  return {
    color: "zam-primary-black",
    tint: "zam-tints-black",
    icon: "horizontal_rule",
  };
};

export const isLoggedIn = () => {
  // either has a company or is a master admin
  // Must also have a token

  return (
    Store.state.auth.authToken && (Store.state.auth.user?.companyId || Store.state.auth.user?.role === "master_admin")
  );
};

export const isOwner = () => {
  return Store.state.auth.user?.role === "owner";
};

export const checkFeatureAccess = (features, next, customNext) => {
  const companyFeatures = Store.state.auth.company?.features;

  const hasFeature = features.some((feature) => companyFeatures?.includes(feature));
  const isFreeTrial = Store.state.auth.company?.isFreeTrial;

  if (hasFeature || isFreeTrial) {
    customNext ? customNext() : next();
  } else {
    next({ name: "upgrade_plan", params: { key: "upgrade" } });
  }
};

export const checkPermission = (model) => {
  const user = Store.state.auth.user;
  return user.role === "owner" || user.permissions.hasOwnProperty(model);
};

export const areFiltersApplied = (filters) => {
  for (let key in filters) {
    if (
      filters[key]?.length ||
      (filters[key] && !Array.isArray(filters[key]))
      // Array.isArray is used  here because [] is truthy so clear all filters tag appears
    )
      return true;
  }
  return false;
};

export const colorToHEX = (name) => {
  // Create fake div
  const fakeDiv = document.createElement("div");
  fakeDiv.style.color = name;
  document.body.appendChild(fakeDiv);

  // Get color of div
  const style = window.getComputedStyle(fakeDiv);
  const value = style.getPropertyValue("color");

  // Remove div after obtaining desired color value
  document.body.removeChild(fakeDiv);

  // convert to hex
  let rgb = value.substring(4).split(")")[0].split(","),
    r = (+rgb[0]).toString(16),
    g = (+rgb[1]).toString(16),
    b = (+rgb[2]).toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;

  return "#" + r + g + b;
};

export const validateEmail = (value) => {
  if (!value) return false;

  const regex =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return regex.test(value.toLowerCase());
};

// func used to pull list of keys from object
export const destruct = (obj, keys) => {
  return keys.reduce((newObj, key) => ({ ...newObj, [key]: obj[key] }), {});
};

export const isLocalCompany = (company) => {
  const address = company.billingAddress || company.shippingAddress;
  const country = address?.country || "egypt";
  return ["egypt", "eg"].includes(country.toLowerCase());
};
