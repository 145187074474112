export function updateStore(state, payload) {
  state.store = { ...state.store, ...payload };
}

export function updateStoreLoading(state, payload) {
  state.store = { ...state.store, loading: payload };
}

export function updateIndustries(state, payload) {
  state.industries = { ...state.industries, data: payload };
}

export function updateIndustriesLoading(state, payload) {
  state.industries = { ...state.industries, loading: payload };
}

export function updateCheckSubdomainLoading(state, payload) {
  state.checkSubdomainLoading = payload;
}
