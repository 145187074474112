export default function () {
  return {
    metadata: {
      loading: false,
      error: null,
    },
    newDiscount: {
      loading: false,
      error: null,

      basicInfo: {
        name: "",
        isAutomatic: false,
      },
      value: {
        valueType: "percentage",
        value: 0,
      },
      minXQuantity: 1,
      maxYQuantity: 1,
      targetSelection: {
        CustomerBuys: "Product",
        CustomerGets: "Product",
      },
      status: "",
      conditions: {
        minPurchaseValue: 0,
      },
      dates: {
        startsAt: new Date(),
        endsAt: null,
      },
      usage: {
        maxUses: null,
        oncePerCustomer: false,
        onlySignedInCustomers: false,
        customers: [],
      },
      type: "ProductsDiscount",
      discountItems: [],
      isXExactMatch: false,
      isYExactMatch: false,
    },
    currentDiscounts: {
      loading: false,
      data: [],
      metadata: {
        totalCount: 0,
        totalPages: 0,
      },
    },
    discountDetails: {
      discount: null,
      products: [],
      loading: false,
      productsLoading: false,
    },
  };
}
