import { constructNestedNavigation, flattenNavigationList } from "src/pages/Dashboard/Navigation/utils";

const mapIsDisabled = (list) =>
  list.map((element) => {
    return { ...element, isDisabled: element.status === "hidden" };
  });

export const formatNavigationsFromAPI = (navigations) => {
  return navigations;
};

export const formatNavigationsToAPI = (navigations) => {
  const flatNavigations = flattenNavigationList(navigations);
  const formattedNavigations = mapIsDisabled(flatNavigations);
  return formattedNavigations;
};
