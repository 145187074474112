/**
 * A recursive DFS implantation to flatten a nested object
 * Like { ..., items: [ { ..., items: [] } ] }
 * to a flat array with a level for each element (how many levels deep it is nested)
 */
export const flattenNavigationList = (list) => {
  const stack = [];
  dfsHelper(list, stack);

  return stack.map((element, index) => {
    return { ...element, order: index };
  });
};

const dfsHelper = (list, stack, parentId = null) => {
  list.forEach((element) => {
    stack.push({ ...element, parentId });
    if (element.items?.length) {
      dfsHelper(element.items, stack, element.id);
    }
  });
};
