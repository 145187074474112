import { i18n } from "boot/i18n";
import parseISO from "date-fns/parseISO";

const $t = i18n.global.t;

import differenceInDays from "date-fns/differenceInDays";
import differenceInYears from "date-fns/differenceInYears";
import format from "date-fns/format";
import isSameDay from "date-fns/isSameDay";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import subDays from "date-fns/subDays";
import subMonths from "date-fns/subMonths";
import { enUS, ar } from "date-fns/locale";
import { Store } from "src/store";

// TODO: apply locale
export const formatDateRange = (range) => {
  const dateRanges = getDateRanges();
  // handle ill-formatted ranges
  if (!range || !range.to || !range.from) return "";

  // handle special ranges, i.e. "Last month"
  for (const specialRange of dateRanges) {
    if (isSameDay(specialRange.to, range.to) && isSameDay(specialRange.from, range.from)) {
      return specialRange.longLabel;
    }
  }

  // normal ranges
  let fromDateFormat = "dd MMM";
  let toDateFormat = "dd MMM";

  // incase difference is more than a year, append year to date
  if (differenceInYears(range.to, range.from) >= 1) {
    fromDateFormat += ", yyyy";
    toDateFormat += ", yyyy";
  }

  // incase difference is less than a day, append hour to date
  if (differenceInDays(range.to, range.from) < 1) {
    fromDateFormat += ", K:mm a";
    toDateFormat = "K:mm a"; // to only display the date once
  }

  return `
    ${format(range.from, fromDateFormat)} -
    ${format(range.to, toDateFormat)}
    `;
};

export const getTimescale = (range) => {
  const days = differenceInDays(range.to, range.from);

  switch (true) {
    case days < 2:
      return "hour";
    case days < 50:
      return "day";
    case days < 150:
      return "week";
    case days < 900:
      return "month";
    default:
      return "year";
  }
};

export function getDateRanges() {
  return [
    {
      shortLabel: $t("charts.today"),
      longLabel: $t("charts.today"),
      from: startOfDay(new Date()),
      to: endOfDay(new Date()),
    },
    {
      shortLabel: $t("charts.yesterday"),
      longLabel: $t("charts.yesterday"),
      from: startOfDay(subDays(new Date(), 1)),
      to: endOfDay(subDays(new Date(), 1)),
    },
    {
      shortLabel: "7D",
      longLabel: $t("charts.week"),
      from: startOfDay(subDays(new Date(), 7)),
      to: endOfDay(new Date()),
    },
    {
      shortLabel: "30D",
      longLabel: $t("charts.month"),
      from: startOfDay(subDays(new Date(), 20)),
      to: endOfDay(new Date()),
    },
    {
      shortLabel: "3M",
      longLabel: $t("charts.quarter"),
      from: startOfDay(subMonths(new Date(), 3)),
      to: endOfDay(new Date()),
    },
    {
      shortLabel: "1Y",
      longLabel: $t("charts.year"),
      from: startOfDay(subMonths(new Date(), 12)),
      to: endOfDay(new Date()),
    },
    {
      shortLabel: $t("charts.all_time"),
      longLabel: $t("charts.all_time"),
      from: parseISO(Store.state.auth.company?.createdAt),
      to: endOfDay(new Date()),
    },
  ];
}

export const formatDateTime = (locale, date, dateTimeFormat = "d MMM, hh:mm aa") => {
  return format(new Date(date), dateTimeFormat, {
    locale: locale === "ar" ? ar : enUS,
  });
};
