import CustomerAPI from "src/api/Customer";

export async function getCurrentCustomers({ commit }, { payload, append }) {
  if (!append) commit("updateCurrentCustomersLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 3,
    order: payload.order || { id: "desc" },
  };
  const query = {
    searchQuery: payload.searchQuery || null,
    purchaseQuery: payload.purchaseQuery || null,
    customerCreatedDate: payload.customerCreatedDate || null,
  };

  try {
    let result = await CustomerAPI.getCustomers(query, pagination);
    if (append) commit("addCustomers", result);
    else commit("updateCurrentCustomersList", result);
  } finally {
    if (!append) commit("updateCurrentCustomersLoading", false);
  }
}

export async function addCustomer({ commit }, payload) {
  commit("updateAddCustomerLoading", true);

  try {
    const customerInfo = await CustomerAPI.addCustomer(payload);
    const addressInfo = await CustomerAPI.addCustomerAddress(customerInfo.customer, payload.addressInfo);
    return { customerInfo, addressInfo };
  } finally {
    commit("updateAddCustomerLoading", false);
  }
}

export async function addAddress({ commit }, payload) {
  commit("updateAddAddressLoading", true);

  try {
    const addressInfo = await CustomerAPI.addCustomerAddress(payload.customer, payload.newAddressInfo);
    return { addressInfo };
  } finally {
    commit("updateAddAddressLoading", false);
  }
}

export async function markAddressAsDefault({ commit }, payload) {
  commit("updateMarkAsDefaultLoading", true);

  try {
    const addressInfo = await CustomerAPI.updateCustomerAddress(payload.addressId, {
      address: {
        isDefault: true,
      },
    });
  } finally {
    commit("updateMarkAsDefaultLoading", false);
  }
}

export async function getCustomer({ commit }, id) {
  commit("resetCustomerDetails");
  commit("updateCustomerDetailsLoading", true);

  try {
    const response = await CustomerAPI.getCustomer(id);
    commit("updateCustomerDetails", response);
    return response;
  } finally {
    commit("updateCustomerDetailsLoading", false);
  }
}

export async function importCustomers({ commit }, file) {
  commit("updateImportLoading", true);
  try {
    await CustomerAPI.importCustomers(file);
  } finally {
    commit("updateImportLoading", false);
  }
}

export async function exportCustomers({ commit, rootState }, payload) {
  commit("updateExportLoading", true);
  const query = {
    searchQuery: payload.searchQuery || null,
  };
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
  };
  try {
    const storeName = rootState.auth.company?.name || "Zammit";
    const date = new Date().toDateString().replaceAll(" ", "-");
    await CustomerAPI.exportCustomers(`${storeName}-customers-${date}`, query, pagination);
  } finally {
    commit("updateExportLoading", false);
  }
}

export async function getSpendingRange({ commit }) {
  commit("updateSpendingRangeLoading", true);

  try {
    const response = await CustomerAPI.getSpendingRange();
    commit("updateSpendingRange", response);
    return response;
  } finally {
    commit("updateSpendingRangeLoading", false);
  }
}
