import ZammitPartnerAPI from "src/api/ZammitPartner";

export async function getZammitPartnerCategories({ commit }) {
  commit("updatePartnerCategoriesLoadingState", true);
  try {
    let result = await ZammitPartnerAPI.getZammitPartnerCategories();
    commit("updatePartnerCategories", result.data);
  } finally {
    commit("updatePartnerCategoriesLoadingState", false);
  }
}

export async function getZammitPartners({ commit }) {
  commit("updateZammitPartnersLoadingState", true);
  try {
    let result = await ZammitPartnerAPI.getZammitPartners();
    commit("updateZammitPartners", result.data);
  } finally {
    commit("updateZammitPartnersLoadingState", false);
  }
}
