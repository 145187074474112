import CollectionAPI from "src/api/Collection";
import min from "lodash/min";

export async function getCurrentCollections({ commit }, { payload, append }) {
  if (!append) commit("updateCurrentCollectionsLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order || { id: "desc" },
  };
  const query = {
    searchQuery: payload.searchQuery || null,
  };

  const isSmart = payload.collectionType === "manual" ? false : payload.collectionType === "smart" ? true : null;

  try {
    let result = await CollectionAPI.getCollections(query, pagination, isSmart);
    if (append) commit("addCollections", result);
    else commit("updateCurrentCollectionsList", result);
  } finally {
    if (!append) commit("updateCurrentCollectionsLoading", false);
  }
}

export async function addCollection({ commit }, payload) {
  commit("updateAddCollectionLoading", true);
  try {
    const result = await CollectionAPI.addCollection(payload);
    return result;
  } finally {
    commit("updateAddCollectionLoading", false);
  }
}

export async function getCollection({ commit }, { id, payload }) {
  commit("resetCollectionDetails");
  commit("updateCollectionLoading", true);

  const pagination = {
    page: payload?.page || 1,
    limit: payload?.limit || 25,
  };

  try {
    const [collection, products] = await Promise.all([
      CollectionAPI.getCollection(id),
      CollectionAPI.getProducts(id, pagination),
    ]);

    commit("updateCollectionProducts", products);
    commit("updateCollectionDetails", collection);
    return { collection, products };
  } finally {
    commit("updateCollectionLoading", false);
  }
}

export async function editCollection({ commit, state }, payload) {
  commit("updateCollectionLoading", true);

  try {
    const requests = [];
    requests.push(CollectionAPI.editCollection(payload.id, payload.collection));

    if (payload.collection.sort === "manual") {
      const startOrder = min(state.collectionDetails.products.map((p) => p.order)) || 0;
      const sortedProducts = state.collectionDetails.products.map((collectionProduct, index) => {
        return {
          id: collectionProduct.id,
          order: startOrder + index,
        };
      });
      requests.push(CollectionAPI.orderProducts(payload.id, sortedProducts));
    }

    const results = await Promise.all(requests); // will return [collections, orderResult (optionally)]
    const products = await CollectionAPI.getProducts(payload.id);

    if (!payload.collection.media) {
      const collection = await CollectionAPI.deleteCollectionImage(payload.id);
      results[0] = collection; // overwrite the collection result
    }

    commit("updateCollectionProducts", products);
    commit("updateCollectionDetails", results[0]);
    return { collection: results[0], products };
  } finally {
    commit("updateCollectionLoading", false);
  }
}

export async function deleteCollection({ commit }, id) {
  commit("updateCollectionLoading", true);

  try {
    const response = await CollectionAPI.deleteCollection(id);
    return response;
  } finally {
    commit("updateCollectionLoading", false);
  }
}

export async function getProducts({ commit, state }, payload) {
  commit("updateCollectionProductsLoading", true);

  const pagination = {
    page: payload?.page || 1,
    limit: payload?.limit || 2,
  };

  try {
    const response = await CollectionAPI.getProducts(state.collectionDetails.collection.id, pagination);

    if (pagination.page === 1) commit("updateCollectionProducts", response);
    else commit("pushCollectionProducts", response);

    return response;
  } finally {
    commit("updateCollectionProductsLoading", false);
  }
}

export async function addProducts({ commit }, { collectionId, products }) {
  commit("updateCollectionProductsLoading", true);

  try {
    await CollectionAPI.addProducts(
      collectionId,
      products.map((product) => {
        return { productId: product.id };
      })
    );
    const response = await CollectionAPI.getProducts(collectionId);

    commit("updateCollectionProducts", response);
    return response;
  } finally {
    commit("updateCollectionProductsLoading", false);
  }
}

export async function deleteProduct({ commit }, { collectionId, productId }) {
  commit("updateCollectionProductsLoading", true);

  try {
    await CollectionAPI.deleteProduct(collectionId, productId);
    const response = await CollectionAPI.getProducts(collectionId);

    commit("updateCollectionProducts", response);
    return response;
  } finally {
    commit("updateCollectionProductsLoading", false);
  }
}
