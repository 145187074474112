export function updateCurrentCustomersLoading(state, response) {
  state.currentCustomers.loading = response;
}

export function updateCurrentCustomersList(state, response) {
  state.currentCustomers.data = response.data;
  state.currentCustomers.metadata = response.metadata;
}

export function addCustomers(state, response) {
  state.currentCustomers.data.push(...response.data);
  state.currentCustomers.metadata = response.metadata;
}

export function updateAddCustomerLoading(state, isLoading) {
  state.newCustomer.loading = isLoading;
}

export function updateAddAddressLoading(state, isLoading) {
  state.newAddressLoading = isLoading;
}

export function updateCustomerDetails(state, response) {
  state.customerDetails = {
    ...state.customerDetails,
    data: response,
  };
}

export function updateMarkAsDefaultLoading(state, isLoading) {
  state.markAsDefaultLoading = isLoading;
}

export function resetCustomerDetails(state) {
  state.customerDetails = {
    loading: false,
    error: null,
    data: null,
  };
}
export function updateCustomerDetailsLoading(state, isLoading) {
  state.customerDetails = {
    ...state.customerDetails,
    loading: isLoading,
  };
}
export function updateExportLoading(state, loading) {
  state.exportLoading = loading;
}

export function updateSpendingRangeLoading(state, loading) {
  state.spendingRange.loading = loading;
}

export function updateSpendingRange(state, response) {
  state.spendingRange = {
    ...state.spendingRange,
    range: response[0],
  };
}
