import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { api } from "boot/axios";

import {
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseMessagingSenderId,
  firebaseAppId,
} from "src/utils/constants";

/**
 * Returns Firebase's auth service
 * https://firebase.google.com/docs/reference/js/firebase.auth.html#callable
 * @returns {Auth} - The Firebase Auth service interface
 */
export const auth = () => {
  return firebase.auth();
};

/**
 * Convenience method to initialize firebase app
 * https://firebase.google.com/docs/reference/js/firebase?authuser=1#initializeapp
 * @param  {Object} config - FIREBASE_CONFIG during the build process
 * @returns {App} - Creates and initializes a Firebase app instance.
 */
export const fBInit = (config) => {
  return firebase.initializeApp(config);
};

export const app = fBInit({
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMessagingSenderId,
  appId: firebaseAppId,
});

export async function sendTokenToServer(currentToken) {
  try {
    const res = await api.post("/api/v2/fcm_device_tokens", {
      token: currentToken,
    });
    console.log("Response from server:", res);
  } catch (err) {
    console.log("Error from server:", err);
    // Handle error appropriately
  }
}
