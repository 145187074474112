export function updatePlans(state, payload) {
  state.allPlans = payload;
}

export function updateLoading(state, isLoading) {
  state.loading = isLoading;
}

export function selectCode(state, code) {
  state.chosenCode = code;
}

export function updateCustomerToken(state, token) {
  state.customerToken = token;
}
