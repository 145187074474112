import { api } from "boot/axios";
import { formatOrder } from "./serializers/orderSerializer";

import { Notify } from "quasar";
import { downloadFile } from "src/utils";

const apiPrefix = "api/v2";
const orderPrefix = "purchases";
const orderProductsPrefix = "purchase_products";
const applyDiscountPrefix = "apply_discount";
const manageOrderPrefix = "manage_on_own";
const pricePrefix = "total_price_range";

// construct orders conditions to not include dashboard draft orders & empty ones
const getOrdersConditions = (isDraft, statusFilter) => {
  const conditions = [];
  const defaultConditions = [
    {
      connector: "or",
      operator: "eq",
      parameter: "status",
      value: "active",
    },
    {
      connector: "or",
      operator: "eq",
      parameter: "status",
      value: "canceled",
    },
  ];
  const draftCondition = [
    {
      connector: "and",
      operator: "not_eq",
      parameter: "channel",
      value: "dashboard",
    },
    {
      connector: "and",
      operator: "eq",
      parameter: "status",
      value: "draft",
    },
  ];

  if (!isDraft && statusFilter?.length === 0) {
    conditions.push(...defaultConditions);
  } else {
    if (isDraft || statusFilter?.includes("draft")) {
      conditions.push(...draftCondition);
    }
    if (statusFilter && !statusFilter?.includes("draft")) {
      conditions.push(
        ...statusFilter.map((filter) => ({
          connector: "or",
          operator: "eq",
          parameter: "status",
          value: filter,
        }))
      );
    }
  }

  return conditions;
};

const OrderAPI = {
  async getOrders(query, pagination, filterOptions) {
    const url = filterOptions?.placedOn
      ? `${apiPrefix}/${orderPrefix}?start_date=${encodeURIComponent(
          filterOptions.placedOn.from
        )}&end_date=${encodeURIComponent(filterOptions.placedOn.to)}`
      : `${apiPrefix}/${orderPrefix}`;

    const response = await api.get(url, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        ...(pagination.order && { order: pagination.order }),
        includes: JSON.stringify([
          { relation: "creator" },
          { relation: "customer" },
          { relation: "discount" },
          { relation: "purchase_products" },
        ]),
        conditions: JSON.stringify(getOrdersConditions(query.isDrafts, filterOptions?.status)),
        ...(query.searchQuery && { search: query.searchQuery }),
        ...(filterOptions?.fulfillment?.length && {
          fulfillment: filterOptions.fulfillment,
        }),
        ...(filterOptions?.payment?.length && {
          payment: filterOptions.payment,
        }),
        ...(filterOptions?.paymentType?.length && {
          payment_type: filterOptions.paymentType,
        }),
        ...(filterOptions?.vendors && { vendor: filterOptions.vendors }),
        // uncomment after refactoring couriers flow in BE
        // ...(filterOptions?.courier.length && {
        //   courier: filterOptions.courier,
        // }),
        ...(filterOptions?.channel && { channel: filterOptions.channel }),
        ...(filterOptions?.discount && {
          discount: { title: filterOptions.discount },
        }),
      },
    });

    return {
      orders: response.data.data,
      metadata: response.data.metadata,
    };
  },
  async getOrdersCount() {
    const response = await api.get(`${apiPrefix}/${orderPrefix}`, {
      params: {
        page: 1,
        limit: 1,
      },
    });
    return response.data.metadata.totalCount;
  },
  async createDraftOrder(newOrder) {
    const payload = formatOrder(newOrder);

    const response = await api.post(`${apiPrefix}/${orderPrefix}`, payload);
    return response.data;
  },
  async fetchMetadata() {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/metadata`);

    return response.data;
  },
  async getOrder(id) {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/${id}`, {
      headers: {
        locale: "all",
      },
      params: {
        includes: JSON.stringify([
          { relation: "purchase_products" },
          { relation: "creator" },
          { relation: "customer" },
          { relation: "address" },
          { relation: "discount" },
          { relation: "histories" },
          { relation: "recipient_customer" },
        ]),
        view: "details",
      },
    });

    return response.data.data;
  },
  async exportOrders(name, query, pagination, filterOptions) {
    const url = filterOptions?.placedOn
      ? `${apiPrefix}/${orderPrefix}/export?start_date=${encodeURIComponent(
          filterOptions.placedOn.from
        )}&end_date=${encodeURIComponent(filterOptions.placedOn.to)}`
      : `${apiPrefix}/${orderPrefix}/export`;

    const response = await api.get(url, {
      params: {
        page: pagination.page,
        limit: pagination.limit,
        ...(pagination.order && { order: pagination.order }),
        includes: JSON.stringify([
          { relation: "creator" },
          { relation: "customer" },
          { relation: "discount" },
          { relation: "purchase_products" },
        ]),
        conditions: JSON.stringify(getOrdersConditions(query.isDrafts, filterOptions?.status)),
        ...(query.searchQuery && { search: query.searchQuery }),
        ...(filterOptions?.fulfillment.length && {
          fulfillment: filterOptions.fulfillment,
        }),
        ...(filterOptions?.payment.length && {
          payment: filterOptions.payment,
        }),
        ...(filterOptions?.paymentType.length && {
          payment_type: filterOptions.paymentType,
        }),
        ...(filterOptions?.vendors.length && {
          vendor: filterOptions.vendors,
        }),
        // uncomment after refactoring couriers flow in BE
        // ...(filterOptions?.courier.length && {
        //   courier: filterOptions.courier,
        // }),
        ...(filterOptions?.channel && { channel: filterOptions.channel }),
        ...(filterOptions?.discount && {
          discount: { title: filterOptions.discount },
        }),
      },
      responseType: "blob",
    });

    if (response.headers["content-type"].includes("application/json")) {
      const message = JSON.parse(await response.data.text()).message;
      Notify.create({ message });
    } else {
      downloadFile(response.data, `${name}.xlsx`);
    }
  },
  async getVooCouriersPricing(id) {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/voo_couriers_pricings/${id}`);
    return response.data.data;
  },
  async getEyedelCouriersPricing(id) {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/eyedel_couriers_pricings/${id}`);
    return response.data.data;
  },
  async submitMultipleShipments(ids) {
    const response = await api.put(`${apiPrefix}/${orderPrefix}/submit_multiple_shipments`, {
      ids: ids,
    });
    return response.data.success;
  },
  async sendToCourier(id, vooCourier) {
    const response = await api.put(
      `${apiPrefix}/${orderPrefix}/send_to_courier/${id}`,
      {
        ...(vooCourier && { voo_courier: vooCourier }),
      },
      {
        params: {
          includes: JSON.stringify([
            { relation: "purchase_products" },
            { relation: "customer" },
            { relation: "address" },
            { relation: "discount" },
            { relation: "histories" },
          ]),
          view: "details",
        },
      }
    );
    return response.data.purchase;
  },
  async markOrderAsPaid(id) {
    const response = await api.put(
      `${apiPrefix}/${orderPrefix}/${id}`,
      {
        purchase: {
          payment: "paid",
        },
      },
      {
        params: {
          includes: JSON.stringify([
            { relation: "purchase_products" },
            { relation: "customer" },
            { relation: "address" },
            { relation: "discount" },
            { relation: "histories" },
          ]),
          view: "details",
        },
      }
    );
    return response.data.purchase;
  },

  async printAirwayBill(id) {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/airwaybill/${id}`, {
      responseType: "blob",
    });

    switch (response.headers["content-type"]) {
      case "text/html":
        const billData = response.data.data;
        if (billData?.message) {
          Notify.create({ message: billData.message });
        } else {
          downloadFile(response.data, `Airway bill #${id}.html`);
        }
        break;

      case "application/pdf":
        downloadFile(response.data, `Airway bill #${id}.pdf`);
        break;

      default:
        const text = await response.data.text();
        const data = JSON.parse(text);
        if (data.format === "redirect" && data.redirectUrl) {
          window.open(data.redirectUrl, "_blank");
        }
        break;
    }
  },

  async bulkAirwayBills(ids) {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/own_airwaybills`, {
      params: {
        ids: ids,
      },
      responseType: "blob",
    });

    const billData = response.data.data;
    if (billData?.message) {
      Notify.create({ message: billData.message });
    } else {
      downloadFile(response.data, `Airway bill #${ids}.html`);
    }
  },
  async generateEInvoice(id) {
    const response = await api.get(`${apiPrefix}/${orderPrefix}/${id}/e_invoice`, {
      responseType: "blob",
    });

    switch (response.headers["content-type"]) {
      case "text/html":
        const invoiceData = response.data.data;
        if (invoiceData?.message) {
          Notify.create({ message: invoiceData.message });
        } else {
          downloadFile(response.data, `E-Invoice #${id}.html`);
        }
        break;

      case "application/pdf":
        downloadFile(response.data, `E-Invoice #${id}.pdf`);
        break;

      default:
        const text = await response.data.text();
        const data = JSON.parse(text);
        if (data.format === "redirect" && data.redirectUrl) {
          window.open(data.redirectUrl, "_blank");
        }
        break;
    }
  },
  async cancelOrder(id) {
    const response = await api.put(`${apiPrefix}/${orderPrefix}/cancel/${id}`);
    return response.data.success;
  },
  async deleteOrder(id) {
    const response = await api.delete(`${apiPrefix}/${orderPrefix}/${id}`);
    return response.data.success;
  },
  async updateOrder(id, purchase) {
    const response = await api.put(`${apiPrefix}/${orderPrefix}/${id}`, purchase, {
      params: {
        includes: JSON.stringify([
          { relation: "purchase_products" },
          { relation: "customer" },
          { relation: "address" },
          { relation: "discount" },
        ]),
        view: "details",
      },
    });
    return response.data.purchase;
  },
  async updateOrderShipping(id, purchase) {
    const response = await api.put(`${apiPrefix}/${orderPrefix}/${manageOrderPrefix}/${id}`, {
      purchase,
    });
    return response.data.purchase;
  },

  async updateOrderFulfillment(id, purchase) {
    const response = await api.put(`${apiPrefix}/${orderPrefix}/${id}`, purchase, {
      params: {
        includes: JSON.stringify([
          { relation: "purchase_products" },
          { relation: "customer" },
          { relation: "address" },
          { relation: "discount" },
          { relation: "histories" },
        ]),
        view: "details",
      },
    });
    return response.data.purchase;
  },

  async applyDiscount(id, discountId) {
    const response = await api.put(
      `${apiPrefix}/${orderPrefix}/${applyDiscountPrefix}/${id}`,
      {
        discountId: discountId,
      },
      {
        params: {
          includes: JSON.stringify([
            { relation: "purchase_products" },
            { relation: "customer" },
            { relation: "address" },
            { relation: "discount" },
          ]),
          view: "details",
        },
      }
    );

    return response.data.purchase;
  },

  async deletePurchaseProduct(id) {
    const response = await api.delete(`${apiPrefix}/${orderProductsPrefix}/${id}`, {
      params: {
        includes: JSON.stringify([{ relation: "purchase_products" }]),
        view: "details",
      },
    });

    return response.data.data;
  },
  async bulkUpdateOrders(ids, data) {
    const response = await api.put(`${apiPrefix}/${orderPrefix}/update_all`, {
      ids,
      ...data,
    });

    return response.data.success;
  },
};

export default OrderAPI;
