export default function () {
  return {
    metadata: {
      loading: false,
      error: null,
      types: [],
      vendors: [],
      tags: [],
      collections: [],
      statuses: [],
    },
    newProduct: {
      loading: false,
      error: null,

      additionalFees: [],
      hasAdditionalFees: false,
      media: [],
      basicInfo: {
        name: {
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
        ribbonLabel: {
          en: "",
          ar: "",
        },
        handle: "",
      },
      variants: {
        options: [],
        value: [],
      },
      pricing: {
        pricedBy: "unit",
        pricingUnit: "",
        isOnSale: false,
        isVat: false,
        price: 0,
        discountedPrice: 0,
      },
      extras: [],
      categorization: {
        type: "",
        vendor: "",
        tags: [],
        collections: [],
        googleProductCategory: "",
      },
      inventory: {
        sku: "",
        barCode: "",
        isTracked: true,
        quantity: 0,
        isLimited: false,
        isSoldOut: false,
        minQuantity: 1,
        maxQuantity: 1000,
      },
      shipping: {
        isPhysical: true,
        addShippingFees: true,
        weight: 0,
      },
      status: "hidden",
    },
    productDetails: {
      loading: false,
      error: null,
      data: null,
    },
    currentProducts: {
      loading: false,

      productList: [],
      outOfStockCount: 0,
      metadata: {
        totalCount: 0,
      },
    },
    availableProducts: {
      loading: false,
      productList: [],
      metadata: {
        totalCount: 0,
      },
    },
    exportLoading: false,
    importLoading: false,
    instagram: {
      loading: false,
      posts: [],
      token: null,
    },
  };
}
