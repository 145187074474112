export default function () {
  return {
    loading: false,
    currentOrders: {
      orderList: [],
      metadata: {
        totalCount: 0,
      },
    },
    draftOrders: {
      orderList: [],
      metadata: {
        totalCount: 0,
      },
    },
    metadata: {
      loading: false,
      error: null,
      data: null,
    },
    orderDetails: {
      loading: false,
      error: null,
      data: null,
    },
    newOrder: {
      loading: false,
      data: null,
      products: [],
      error: null,
    },
    exportLoading: false,
  };
}
