export function updateChart(state, { data, chart }) {
  state.charts[chart] = {
    ...state.charts[chart],
    ...data,
  };
}

export function updateChartLoading(state, { chart, loading }) {
  state.charts[chart] = {
    ...state.charts[chart],
    loading,
  };
}

export function updateChartError(state, { chart, error }) {
  state.charts[chart] = {
    ...state.charts[chart],
    error,
  };
}
