import { api } from "boot/axios";

const apiPrefix = "api/v2";

const CourierAPI = {
  async pullKhazenlyStatuses(id) {
    const response = await api.get(`${apiPrefix}/couriers/khazenly/pull_order_status/${id}`);
    return response.data.success;
  },
};

export default CourierAPI;
