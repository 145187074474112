import Router from "src/router";
import { Store } from "src/store";

import { i18n } from "boot/i18n";
import { useCompanyDetails } from "src/composables/queries";

const $t = i18n.global.t;

export const PRICE_TYPES = [
  {
    label: $t("products.add_product.pricing.priced_by.options.unit"),
    value: "unit",
    baseUnit: "",
  },
  {
    label: $t("products.add_product.pricing.priced_by.options.product_length"),
    value: "product_length-cm",
    baseUnit: "CM",
  },
  {
    label: $t("products.add_product.pricing.priced_by.options.product_length"),
    value: "product_length-m",
    baseUnit: "M",
  },
  {
    label: $t("products.add_product.pricing.priced_by.options.area"),
    value: "area-cm^2",
    baseUnit: "CM",
  },
  {
    label: $t("products.add_product.pricing.priced_by.options.area"),
    value: "area-m^2",
    baseUnit: "M",
  },
  {
    label: $t("products.add_product.pricing.priced_by.options.weight"),
    value: "weight-g",
    baseUnit: "G",
  },
  {
    label: $t("products.add_product.pricing.priced_by.options.weight"),
    value: "weight-kg",
    baseUnit: "KG",
  },
];

const currencyLocale = () => {
  const routerLocale = Router.currentRoute.value.params.locale;
  return routerLocale === "ar" ? "ar-EG" : "en-US";
};

export const formatPrice = (price) => {
  const currency = Store.getters["auth/currency"];
  const maximumDecimals = Store.getters["auth/priceDecimalPoints"];
  return Intl.NumberFormat(currencyLocale(), {
    style: "currency",
    currency,
    maximumFractionDigits: maximumDecimals,
  }).format(price);
};

export const currencySymbol = () => {
  const currency = Store.getters["auth/currency"];

  return (0)
    .toLocaleString(currencyLocale(), {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "") // English "zero"
    .replace(/[\u0660-\u0669\u06f0-\u06f9]/g, "") // Arabic "zero"
    .trim();
};

export const shouldShowCodFee = (paymentType, codFee) => {
  return ["cash_on_delivery", "card_on_delivery"].includes(paymentType) && codFee > 0;
};
