export default {
  title: "نحن معك",
  subtitle: "نحن نجعل حياتك أسهل من خلال تقديم الأفضل لك في السوق",
  choose_favourite: "اختر المفضلة لديك",
  contact: "اتصل",
  offer: "عرض",
  apply: "قدم الآن",
  errors: {
    category_error: "خطأ في جلب فئات شركاء زاميت",
    partner_error: "خطأ في جلب بيانات شركاء زاميت",
  },
};
