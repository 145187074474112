import parseISO from "date-fns/parseISO";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { v4 as uuidv4 } from "uuid";

export const flattenDiscount = (discount) => {
  return {
    startsAt: discount.dates.startsAt,
    endsAt: discount.dates.endsAt,
    title: discount.basicInfo.name,
    valueType: discount.value.valueType,
    value: discount.value.value,
    minimumPurchaseValue: discount.conditions.minPurchaseValue,
    minXQuantity: discount.minXQuantity,
    maxYQuantity: discount.maxYQuantity,
    type: discount.type,
    productIds: discount.products?.map((product) => product.id),
    collectionIds: discount.collections?.map((collection) => collection.id),
    customerIds: discount.usage.customers?.map((customer) => customer.id),
    discountItems: discount.discountItems.map(reduceDiscountItems),
    ...discount.usage,
    isXExactMatch: discount.isXExactMatch,
    isYExactMatch: discount.isYExactMatch,
    isAutomatic: discount.basicInfo.isAutomatic,
  };
};

export const formatDiscount = (discount) => {
  let payload = {
    id: discount.id,
    basicInfo: {
      name: discount.title,
      isAutomatic: discount.isAutomatic,
    },
    type: discount.type,
    value: {
      valueType: discount.valueType,
      value: discount.value,
    },
    usage: {
      maxUses: discount.maxUses,
      oncePerCustomer: discount.oncePerCustomer,
      customers: discount.customers,
      onlySignedInCustomers: discount.onlySignedInCustomers,
    },
    status: discount.status,
    minXQuantity: discount.minXQuantity,
    maxYQuantity: discount.maxYQuantity,
    conditions: {
      minPurchaseValue: discount.minimumPurchaseValue,
    },
    dates: {
      startsAt: discount.startsAt ? parseISO(discount.startsAt) : null,
      endsAt: discount.endsAt ? parseISO(discount.endsAt) : null,
    },
    targetSelection: {
      CustomerBuys: getTargetSelection(discount.discountItems, "CustomerBuys") || "Product",
      CustomerGets: getTargetSelection(discount.discountItems, "CustomerGets") || "Product",
    },
    discountItems: formatDiscountItems(discount.discountItems),
    products: discount.products || [],
    collections: discount.collections || [],
    isXExactMatch: discount.isXExactMatch,
    isYExactMatch: discount.isYExactMatch,
  };

  return payload;
};

export const formatDiscountStatus = (status) => {
  return { isDisabled: status === "inactive" };
};

export const getTargetSelection = (items, key) => {
  return items.find((item) => item.type === key)?.itemType === "Product" ? "Product" : "Collection";
};

export const formatDiscountItems = (items) => {
  return items.map((item) => {
    return {
      id: item.itemId,
      itemType: item.itemType,
      type: item.type,
      thumbUrl: item.item.thumbUrl || item.item.image?.src || "",
      name: item.item.name,
    };
  });
};

export const reduceDiscountItems = (item) => {
  const { id, itemType, type } = item;

  return { itemId: id, itemType, type: type };
};

export const createInstantDiscount = (discount) => {
  let payload = {
    isInstant: true,
    startsAt: startOfDay(new Date()).toISOString(),
    endsAt: endOfDay(new Date()).toISOString(),
    title: uuidv4(),
    valueType: discount.valueType,
    value: discount.value,
  };
  return payload;
};
