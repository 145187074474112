export const formatOrder = (payload) => {
  let data = {
    purchase: {
      selected_products: [],
      status: "draft",
      channel: "dashboard",
      payment: "unpaid",
      paymentType: "cash_on_delivery",
      fulfillment: "unfulfilled",
      is_manually_created: true,
      ...payload,
    },
  };

  return data;
};
