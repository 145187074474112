import { scrollToTop } from ".";
import { Notify } from "quasar";

import { i18n } from "boot/i18n";
const $t = i18n.global.t;

/**
 * @param error error object
 * @param fallback fallback translation keys incase the error object does not contain a message
 * @param scroll scroll to the top if true
 */
export const formatErrors = (error, fallback, scroll) => {
  const message = error?.response?.data?.error?.message || $t(fallback);

  if (scroll) scrollToTop();
  return message;
};

export const errorNotification = (error, fallback) => {
  if (error?.response?.handled) return;

  const message = error?.response?.data?.error?.message || $t(fallback);
  Notify.create({
    type: "negative",
    message,
  });
};

/**
 * // Global error handler
 * @returns if the error was handled or not
 */
export const handleErrors = (error, router) => {
  // No internet connection
  if (!error.response || !error.response.status) {
    Notify.create({
      icon: "icon-zam-no-wifi",
      type: "negative",
      message: $t("general.no_internet"),
    });
    return true;
  }

  // Redirect to the unauthorized access screen incase of 403 error
  if (error.response.status === 403) {
    Notify.create({
      type: "warning",
      message: $t("general.unauthorized_access"),
    });
    return true;
  }

  // Generic error / Internal server error (500)
  if (!error.response.data || !error.response.data.error) {
    // router.push({
    //   name: "500",
    //   params: {
    //     headerTitle: router.currentRoute.value,
    //   },
    // });

    Notify.create({
      type: "negative",
      message: $t("general.generic_error"),
    });
    return true;
  }

  /**
   * Redirect to login for unauthorized requests
   * Except in auth module
   *
   * This will mainly trigger when the user has a token, so he will bypass the route guards
   * but the token is expired so it will return a 401
   *  */
  if (
    error.response.status === 401 &&
    router.currentRoute.value.matched.every((route) => route.name !== "auth")
    // does not match with the auth route (MainLayout.vue)
    // This means this is a non-auth route
  ) {
    router.push({ name: "signOut" });
    Notify.create({
      type: "warning",
      message: $t("errors.auth.re_login"),
    });
    return true;
  }

  // Redirect to the plan expired screen incase of 402 error
  if (error.response.status === 402) {
    router.push({ name: "renew_plan", params: { key: "renew" } });
    return true;
  }

  // redirect to cod successful screen
  // if (error.response.status === 401) {
  //   router.push({ name: "plan_cod" });
  //   return true;
  // }

  return false;
};
