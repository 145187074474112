export default function () {
  return {
    charts: {
      sales: {
        loading: false,
        error: null,

        timescale: null,
        total_sales: 0,
        total_cancelled: 0,
        total_refunded: 0,
        total_failed: 0,
        previous_duration_start: null,
        previous_duration_end: null,
        previous_duration_sales: 0,
        total_change_percentage: 0,
        graph_points: [],
      },
    },
  };
}
