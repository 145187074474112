<template>
  <router-view />
</template>
<script>
import { defineComponent } from "vue";
import icons from "./utils/icons";
import { app as firebaseApp } from "src/services/firebase";
import { getMessaging, onMessage } from "firebase/messaging";

export default defineComponent({
  name: "App",
  methods: {
    prepareFcm() {
      let messaging = getMessaging(firebaseApp);

      onMessage(messaging, (payload) => {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notification = new Notification(payload.notification.title, {
              body: payload.notification.body,
              icon: payload.notification.icon,
            });
            notification.onclick = function () {
              window.open(payload.notification.click_action, "_blank");
            };
          }
        });
      });
    },
    offline() {
      this.$q.notify({
        type: "negative",
        icon: "icon-zam-no-wifi",
        message: this.$t("general.no_internet"),
      });
    },

    online() {
      this.$q.notify({
        type: "positive",
        icon: "icon-zam-wifi",
        message: this.$t("general.internet_back"),
      });
    },
  },
  mounted() {
    if (!this.$q.platform.is.capacitor) {
      this.prepareFcm();
    }
    this.$q.iconMapFn = (iconName) => {
      const icon = icons[iconName];
      if (icon !== void 0) {
        return { icon };
      }
    };

    window.addEventListener("offline", this.offline);
    window.addEventListener("online", this.online);
  },
  unmounted() {
    window.removeEventListener("offline", this.offline);
    window.removeEventListener("online", this.online);
  },
});
</script>
