import AnalyticsAPI from "src/api/Analytics";
import { getTimescale } from "src/utils/dateUtils";

export async function getChart({ commit }, { chart, range, channel, filters }) {
  commit("updateChartLoading", { chart, loading: true });
  commit("updateChartError", { chart, error: null });

  const timescale = getTimescale(range);

  try {
    const data = await AnalyticsAPI.getChart({
      chart,
      range,
      timescale,
      channel,
      filters,
    });
    commit("updateChart", { chart, data });
    return data;
  } catch (error) {
    if (!error.response.handled)
      commit("updateChartError", {
        chart,
        error: error.response.data?.error?.message || "errors.analytics.get_data",
      });
  } finally {
    commit("updateChartLoading", { chart, loading: false });
  }
}
