import { api } from "boot/axios";
import { paramsMap } from "src/utils/charts";
import { getChannelParams } from "./serializers/utils";

const apiPrefix = "api/v2";
const analyticsPrefix = "analytics";

const AnalyticsAPI = {
  async getChart({ chart, range, timescale, channel, filters }) {
    const params = paramsMap[chart];

    const url = `${apiPrefix}/${analyticsPrefix}/${params.endpoint}?start_date=${encodeURIComponent(
      range.from
    )}&end_date=${encodeURIComponent(range.to)}`;

    const response = await api.get(url, {
      params: {
        timescale,
        status: JSON.stringify(["active", "canceled"]),
        ...(params.type && { type: params.type }),
        ...(params.limit && { limit: params.limit }),
        ...(params.groupBy && { group_by: params.groupBy }),
        ...(channel && getChannelParams(chart, channel)),
      },
    });
    return response.data;
  },
};

export default AnalyticsAPI;
