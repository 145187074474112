import { api } from "boot/axios";

const apiPrefix = "api/v2";
const industryPrefix = "industries";

const IndustryAPI = {
  async getIndustries() {
    const response = await api.get(`${apiPrefix}/${industryPrefix}`);
    return response.data.data;
  },
};

export default IndustryAPI;
