import { api } from "boot/axios";
import { flattenObject, localizeObject } from "src/utils";
import { formatNavigationsFromAPI, formatNavigationsToAPI } from "./serializers/navigationSerializer";

const apiPrefix = "api/v2";
const navigationPrefix = "navigations";

const NavigationAPI = {
  async getNavigations() {
    const response = await api.get(`${apiPrefix}/${navigationPrefix}`);
    const data = response.data.data;

    const formattedData = formatNavigationsFromAPI(data);
    return formattedData;
  },

  async getSingleNavigation(id) {
    const response = await api.get(`${apiPrefix}/${navigationPrefix}/${id}`, {
      headers: { locale: "all" },
    });
    const data = response.data.data;
    const formattedData = {
      ...data,
      ...localizeObject(data, "label", ""),
    };

    return formattedData;
  },

  async updateNavigations(navigations) {
    const formattedNavigations = formatNavigationsToAPI(navigations);

    await api.patch(`${apiPrefix}/${navigationPrefix}`, {
      navigations: formattedNavigations,
    });
  },

  async updateSingleNavigation(id, payload) {
    const formattedData = {
      ...payload,
      ...flattenObject(payload, "label", ""),
    };

    const response = await api.patch(`${apiPrefix}/${navigationPrefix}/${id}`, formattedData, {
      headers: { locale: "all" },
    });
    return response.data.data;
  },
};

export default NavigationAPI;
