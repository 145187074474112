import CourierAPI from "src/api/Courier";
import OrderAPI from "src/api/Order";

export async function getOrders({ commit }, { payload, append }) {
  if (!append) commit("updateOrdersLoading", true);

  const query = {
    searchQuery: payload.searchQuery || null,
  };
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order,
  };
  const filterOptions = payload.filterOptions || {};

  try {
    const result = await OrderAPI.getOrders(query, pagination, filterOptions);
    if (append) commit("addOrders", result);
    else commit("updateOrdersList", result);
  } finally {
    if (!append) commit("updateOrdersLoading", false);
  }
}

export async function getDraftOrders({ commit }, { payload, append }) {
  if (!append) commit("updateOrdersLoading", true);

  const query = {
    isDrafts: true,
    searchQuery: payload.searchQuery || null,
  };
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order,
  };

  try {
    const result = await OrderAPI.getOrders(query, pagination);
    if (append) commit("addDraftOrders", result);
    else commit("updateDraftOrdersList", result);
  } finally {
    if (!append) commit("updateOrdersLoading", false);
  }
}

export async function getMetadata({ commit }) {
  commit("updateMetadataLoading", true);

  try {
    const result = await OrderAPI.fetchMetadata();
    commit("updateMetadata", result);
  } finally {
    commit("updateMetadataLoading", false);
  }
}

export async function getOrder({ commit }, { id }) {
  commit("resetOrderDetails");
  commit("updateOrderDetailsLoading", true);

  try {
    const response = await OrderAPI.getOrder(id);
    if (!response)
      throw {
        response: {
          status: 404,
          data: { error: { message: "Order not found" } },
        },
      };
    commit("updateOrderDetails", response);
    return response;
  } finally {
    commit("updateOrderDetailsLoading", false);
  }
}

export async function getDraftOrder({ commit }, { id }) {
  try {
    const response = await OrderAPI.getOrder(id);
    commit("updateOrder", response);
    return response;
  } finally {
    commit("updateDraftOrderLoading", false);
  }
}

export async function exportOrders({ commit, rootState }, payload) {
  commit("updateExportLoading", true);

  const query = {
    searchQuery: payload.searchQuery || null,
  };
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order,
  };
  const filterOptions = payload.filterOptions || {};

  try {
    const storeName = rootState.auth.company?.name || "Zammit";
    const date = new Date().toDateString().replaceAll(" ", "-");
    await OrderAPI.exportOrders(`${storeName}-orders-${date}`, query, pagination, filterOptions);
  } finally {
    commit("updateExportLoading", false);
  }
}

export async function getVooCouriersPricing({ commit }, { id, isOrderDetails }) {
  if (isOrderDetails) {
    commit("updateOrderDetailsLoading", true);
  } else {
    commit("updateOrdersLoading", true);
  }

  try {
    const result = await OrderAPI.getVooCouriersPricing(id);
    return result;
  } finally {
    if (isOrderDetails) {
      commit("updateOrderDetailsLoading", false);
    } else {
      commit("updateOrdersLoading", false);
    }
  }
}

export async function getEyedelCouriersPricing({ commit }, { id, isOrderDetails }) {
  if (isOrderDetails) {
    commit("updateOrderDetailsLoading", true);
  } else {
    commit("updateOrdersLoading", true);
  }

  try {
    const result = await OrderAPI.getEyedelCouriersPricing(id);
    return result;
  } finally {
    if (isOrderDetails) {
      commit("updateOrderDetailsLoading", false);
    } else {
      commit("updateOrdersLoading", false);
    }
  }
}
export async function submitMultipleShipments({ commit }, { ids }) {
  commit("updateOrdersLoading", true);
  try {
    const response = await OrderAPI.submitMultipleShipments(ids);
    return response;
  } finally {
    commit("updateOrdersLoading", false);
  }
}

export async function sendToCourier({ commit }, { id, vooCourier, isOrderDetails }) {
  if (isOrderDetails) {
    commit("updateOrderDetailsLoading", true);
  } else {
    commit("updateOrdersLoading", true);
  }
  try {
    const response = await OrderAPI.sendToCourier(id, vooCourier);
    commit("updateOrderDetails", response);
    return response;
  } finally {
    if (isOrderDetails) {
      commit("updateOrderDetailsLoading", false);
    } else {
      commit("updateOrdersLoading", false);
    }
  }
}

export async function markOrderAsPaid({ commit }, { id, isOrderDetails }) {
  if (isOrderDetails) {
    commit("updateOrderDetailsLoading", true);
  } else {
    commit("updateOrdersLoading", true);
  }
  try {
    const response = await OrderAPI.markOrderAsPaid(id);
    commit("updateOrderDetails", response);
    return response;
  } finally {
    if (isOrderDetails) {
      commit("updateOrderDetailsLoading", false);
    } else {
      commit("updateOrdersLoading", false);
    }
  }
}

export async function printAirwayBill({ commit }, { id }) {
  commit("updateOrderDetailsLoading", true);

  try {
    const response = await OrderAPI.printAirwayBill(id);
    return response;
  } finally {
    commit("updateOrderDetailsLoading", false);
  }
}

export async function generateEInvoice({ commit }, { id }) {
  commit("updateOrderDetailsLoading", true);

  try {
    const response = await OrderAPI.generateEInvoice(id);
    return response;
  } finally {
    commit("updateOrderDetailsLoading", false);
  }
}

export async function cancelOrder({ commit }, { id, isOrderDetails }) {
  if (isOrderDetails) {
    commit("updateOrderDetailsLoading", true);
  } else {
    commit("updateOrdersLoading", true);
  }
  try {
    const response = await OrderAPI.cancelOrder(id);
    return response;
  } finally {
    if (isOrderDetails) {
      commit("updateOrderDetailsLoading", false);
    } else {
      commit("updateOrdersLoading", false);
    }
  }
}

export async function deleteOrder({ commit }, { id }) {
  commit("updateOrdersLoading", true);
  try {
    const response = await OrderAPI.deleteOrder(id);
    return response;
  } finally {
    commit("updateOrdersLoading", false);
  }
}

export async function createDraftOrder({ commit }, payload) {
  commit("updateCreateDraftOrderLoading", true);
  try {
    const response = await OrderAPI.createDraftOrder(payload);
    commit("updateCreateDraftOrder", response);
    return response;
  } finally {
    commit("updateCreateDraftOrderLoading", false);
  }
}

export async function updateOrderShipping({ commit }, { id, purchase }) {
  commit("updateOrderDetailsLoading", true);
  try {
    const response = await OrderAPI.updateOrderShipping(id, purchase);
    commit("updateOrderDetails", response);
    return response;
  } finally {
    commit("updateOrderDetailsLoading", false);
  }
}

export async function updateOrderFulfillmentStatus({ commit }, { id, purchase, isOrderDetails }) {
  if (isOrderDetails) {
    commit("updateOrderDetailsLoading", true);
  } else {
    commit("updateOrdersLoading", true);
  }
  try {
    const response = await OrderAPI.updateOrderFulfillment(id, { purchase });
    commit("updateOrderDetails", response);
    return response;
  } finally {
    if (isOrderDetails) {
      commit("updateOrderDetailsLoading", false);
    } else {
      commit("updateOrdersLoading", false);
    }
  }
}

export async function updateOrderDiscount({ commit }, { id, discountId }) {
  commit("updateOrdersLoading", true);
  try {
    const response = await OrderAPI.applyDiscount(id, discountId);
    commit("updateOrder", response);
    return response;
  } finally {
    commit("updateOrdersLoading", false);
  }
}

export async function updateOrderProducts({ commit }, { id, purchase }) {
  commit("updateDraftOrderLoading", true);
  try {
    const response = await OrderAPI.updateOrder(id, { purchase });
    commit("updateOrder", response);
    return response;
  } finally {
    commit("updateDraftOrderLoading", false);
  }
}

export async function deleteOrderProduct({ commit }, { id }) {
  commit("updateDraftOrderLoading", true);
  try {
    const products = await OrderAPI.deletePurchaseProduct(id);
    commit("updateOrder", products);
    return { products };
  } finally {
    commit("updateDraftOrderLoading", false);
  }
}
export async function updateOrder({ commit }, { id, purchase }) {
  commit("updateOrdersLoading", true);
  try {
    const response = await OrderAPI.updateOrder(id, { purchase });
    commit("updateOrder", response);
    return response;
  } finally {
    commit("updateOrdersLoading", false);
  }
}

export async function bulkUpdateOrders({ commit }, { ids, data }) {
  commit("updateOrdersLoading", true);
  try {
    await OrderAPI.bulkUpdateOrders(ids, data);
  } finally {
    commit("updateOrdersLoading", false);
  }
}

export async function bulkAirwayBills({ commit }, { ids }) {
  commit("updateOrdersLoading", true);
  try {
    await OrderAPI.bulkAirwayBills(ids);
  } finally {
    commit("updateOrdersLoading", false);
  }
}

export async function refreshKhazenlyOrders({ rootState }) {
  const companyId = rootState.auth.user.companyId;
  await CourierAPI.pullKhazenlyStatuses(companyId);
}
