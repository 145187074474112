import { useQuery } from "@tanstack/vue-query";
import IndustryAPI from "src/api/Industry";

export function useIndustries() {
  return useQuery({
    queryKey: ["industries"],
    queryFn: async () => {
      const response = await await IndustryAPI.getIndustries();
      return response;
    },
  });
}
