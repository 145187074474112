import { i18n } from "boot/i18n";
import operators, { percentageOperators } from "./operators";

const $t = i18n.global.t;

export const formatValueFromAPI = (condition) => {
  let newValue = condition.value;

  // if parameter is price (or discounted price) -> convert from cents
  if (operators[condition.parameter].isCurrency) newValue = condition.value / 100;

  // remove % around value when using does_not_match operator
  if (percentageOperators.includes(condition.operator))
    newValue = condition.value.substring(1, condition.value.length - 1);

  return newValue;
};

export const prepareValueToAPI = (condition) => {
  let newValue = condition.value;

  // if parameter is price (or discounted price) -> convert to cents
  if (operators[condition.parameter].isCurrency) newValue = condition.value * 100;

  // add %<val>% when using does_not_match operator (using LIKE in BE)
  if (percentageOperators.includes(condition.operator)) newValue = `%${condition.value}%`;

  return newValue;
};

export const conditionsToSentences = (conditions) => {
  const sentencesArray = conditions
    .slice(0, 2)
    .map((condition) =>
      condition.parameter == "id"
        ? $t("collections.parameters.id")
        : [
            $t(`collections.parameters.${condition.parameter}`),
            $t("collections.all_collections.is"),
            $t(`collections.operators.${condition.operator}`),
            formatValueFromAPI(condition),
          ].join(" ")
    );

  if (conditions.length > 2)
    sentencesArray.push(
      $t("collections.all_collections.plus_more", {
        num: conditions.length - 2,
      })
    );

  return sentencesArray;
};
