import NavigationAPI from "src/api/Navigation";

export async function getNavigationList({ commit }) {
  commit("updateListLoading", true);

  try {
    const result = await NavigationAPI.getNavigations();
    commit("updateList", result);
  } finally {
    commit("updateListLoading", false);
  }
}

export async function getSingleNavigation({ commit }, id) {
  const result = await NavigationAPI.getSingleNavigation(id);
  commit("updateCurrent", result);
}

export async function updateNavigations({ commit }, payload) {
  commit("updateSaveLoading", true);

  try {
    await NavigationAPI.updateNavigations(payload);
  } finally {
    commit("updateSaveLoading", false);
  }
}

export async function updateSingleNavigation({ commit }, payload) {
  commit("updateSaveLoading", true);

  try {
    await NavigationAPI.updateSingleNavigation(payload.id, payload);
  } finally {
    commit("updateSaveLoading", false);
  }
}
