import CompanyAPI from "src/api/Company";
import IndustryAPI from "src/api/Industry";

export async function getIndustries({ commit }) {
  commit("updateIndustriesLoading", true);

  try {
    const response = await IndustryAPI.getIndustries();
    commit("updateIndustries", response);
    return response;
  } finally {
    commit("updateIndustriesLoading", false);
  }
}

export async function createStore({ commit, state, rootState }) {
  commit("updateStoreLoading", true);

  try {
    const response = await CompanyAPI.createCompany(state.store, rootState.auth.user);

    const company = await CompanyAPI.getCompanyDetails();
    commit("auth/updateCompany", company, { root: true });

    return response;
  } finally {
    commit("updateStoreLoading", false);
  }
}

export async function checkSubdomain({ commit }, payload) {
  commit("updateCheckSubdomainLoading", true);
  try {
    const response = await CompanyAPI.checkSubdomain(payload);
    return response;
  } finally {
    commit("updateCheckSubdomainLoading", false);
  }
}
