import { useQuery } from "@tanstack/vue-query";
import CompanyAPI from "src/api/Company";

export function useCompanyDetails() {
  return useQuery({
    queryKey: ["companyDetails"],
    queryFn: async () => {
      const response = await CompanyAPI.getCompanyDetails_v2();
      return response;
    },
  });
}
