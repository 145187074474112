import { boot } from "quasar/wrappers";
import { createGtm } from "@gtm-support/vue-gtm";

export default boot(({ app, router }) => {
  app.use(
    createGtm({
      id: "GTM-WS3LR3Z",
      vueRouter: router,
      loadScript: true,
      enabled: process.env.ENVIRONMENT === "production", //enable only on production
    })
  );
});
