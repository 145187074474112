import { Notify } from "quasar";

Notify.setDefaults({
  classes: ["q-py-xs HeadingBlack shadow-12"],
  position: "bottom-left",
  textColor: "zam-primary-black",
  badgeClass: "hidden",
  color: "zam-greys-light-background",
  actions: [
    {
      icon: "close",
      color: "zam-primary-black",
      padding: "sm",
      rounded: true,
    },
  ],
});

Notify.registerType("negative", {
  color: "zam-tints-red border-zam-states-error",
  textColor: "zam-states-error",
  icon: "new_releases",
});

Notify.registerType("warning", {
  color: "zam-tints-pale-orange border-zam-dark-orange",
  textColor: "zam-dark-orange",
  icon: "front_hand",
});

Notify.registerType("positive", {
  color: "zam-tints-green border-zam-primary-green",
  textColor: "zam-primary-green",
  icon: "check_circle",
});
