import { useQuery } from "@tanstack/vue-query";
import OrderAPI from "src/api/Order";

export function useOrdersCount() {
  return useQuery({
    queryKey: ["ordersCount"],
    queryFn: async () => {
      const response = await await OrderAPI.getOrdersCount();
      return response;
    },
  });
}
