import CompanyAPI from "src/api/Company";

export async function updateCompany({ commit }, payload) {
  commit("updateCompanyLoading", true);
  try {
    const response = await CompanyAPI.updateCompanyFields(payload);
    return response;
  } finally {
    commit("updateCompanyLoading", false);
  }
}

export async function updateCompanyAddress({ commit }, payload) {
  commit("updateCompanyLoading", true);
  try {
    const response = await CompanyAPI.updateCompanyAddress(payload.address);
    return response;
  } finally {
    commit("updateCompanyLoading", false);
  }
}

export async function updateCompanyPlatforms({ commit }, payload) {
  commit("updateCompanyLoading", true);
  try {
    const response = await CompanyAPI.updateCompanyPlatforms(payload);
    commit("updateCompanyPlatforms", response);
  } finally {
    commit("updateCompanyLoading", false);
  }
}

export async function deleteCompanyPlatform({ commit }, id) {
  commit("updateCompanyLoading", true);
  try {
    await CompanyAPI.deleteCompanyPlatform(id);
  } finally {
    commit("updateCompanyLoading", false);
  }
}

export async function fetchCompanyDetails({ commit }, { geolocations }) {
  commit("fetchCompanyDetailsLoading", true);
  try {
    const response = await CompanyAPI.fetchCompanyDetails(geolocations);
    commit("fetchCompanyDetails", response);
    return response;
  } finally {
    commit("fetchCompanyDetailsLoading", false);
  }
}

export async function fetchCompanyDetailsWithGeolocations({ commit }) {
  commit("fetchCompanyDetailsLoading", true);
  try {
    const response = await CompanyAPI.fetchCompanyDetailsWithGeolocations();
    commit("fetchCompanyDetails", response);
    return response;
  } finally {
    commit("fetchCompanyDetailsLoading", false);
  }
}

export async function fetchPlatforms({ commit }) {
  commit("fetchPlatformsLoading", true);
  try {
    const response = await CompanyAPI.fetchPlatforms();
    commit("fetchPlatforms", response);
    return response;
  } finally {
    commit("fetchPlatformsLoading", false);
  }
}

export async function fetchStoreFeatures({ commit }) {
  commit("fetchStoreFeaturesLoading", true);
  try {
    const response = await CompanyAPI.getStoreFeatures();
    commit("fetchStoreFeatures", response);
    return response;
  } finally {
    commit("fetchStoreFeaturesLoading", false);
  }
}

export async function addStoreFeatures({ commit }, payload) {
  commit("updateCompanyLoading", true);
  try {
    const response = await CompanyAPI.addStoreFeatures(payload);
    commit("updateCompanyStoreFeatures", response);
    return response;
  } finally {
    commit("updateCompanyLoading", false);
  }
}
