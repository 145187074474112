import { useQuery } from "@tanstack/vue-query";
import CompanyAPI from "src/api/Company";

export function useStoreFeatures() {
  return useQuery({
    queryKey: ["storeFeatures"],
    queryFn: async () => {
      const response = await CompanyAPI.getStoreFeatures();
      return response;
    },
  });
}
