import { storeMilestones } from "src/utils/constants";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import parseISO from "date-fns/parseISO";

export function isNewShop(state) {
  if (!state.company) return false;

  // true if at least 1 milestone is false
  const didNotCompleteMilestones = storeMilestones
    .map((milestone) => state.company[milestone.name])
    .some((milestone) => !milestone);

  const storeDays = differenceInBusinessDays(new Date(), parseISO(state.company.createdAt));

  return didNotCompleteMilestones && storeDays < 7;
}

export function getStoreOnboardingPercentage(state) {
  if (!state.company) return 0;

  const sum = storeMilestones.map((milestone) => state.company[milestone.name]).reduce((prev, curr) => prev + curr, 0); //sums all booleans

  return sum / storeMilestones.length;
}

export function currency(state) {
  return state.company?.paymentCurrency || "EGP";
}

export function priceDecimalPoints(state) {
  return state.company?.priceDecimalPoints || 0;
}
