import PlanAPI from "src/api/Plan";

export async function getPlans({ commit }) {
  commit("updateLoading", true);

  try {
    const plans = await PlanAPI.getPlans();
    commit("updatePlans", plans);
  } finally {
    commit("updateLoading", false);
  }
}

export async function getCustomerToken({ commit }) {
  commit("updateLoading", true);

  try {
    const token = await PlanAPI.getCustomerToken();
    commit("updateCustomerToken", token);
  } finally {
    commit("updateLoading", false);
  }
}
