import { useQuery } from "@tanstack/vue-query";
import LocationAPI from "src/api/Location";

export function useLocations({ locationType, parentId }) {
  return useQuery({
    queryKey: ["locations", locationType, parentId],
    queryFn: async () => {
      const parentIdValue = parentId?.value;
      if (!parentIdValue) {
        if (locationType === "country") {
          return await LocationAPI.getLocations({
            locationType,
          });
        }
        return [];
      }
      return await LocationAPI.getLocations({
        locationType,
        parentId: parentIdValue,
      });
    },
  });
}
