import { api } from "boot/axios";

const apiPrefix = "api/v2";
const authPrefix = "authentication";

const AuthAPI = {
  async updateUser(payload) {
    const response = await api.put(`${apiPrefix}/users`, payload);
    return response.data.user;
  },

  async hasAccount(payload) {
    const response = await api.post(`${apiPrefix}/users/has_account`, payload);
    return response.data;
  },

  async emailLogin(payload) {
    const response = await api.post(`${apiPrefix}/${authPrefix}/email`, payload);

    return response.data;
  },

  async getCurrentUser() {
    const response = await api.get(`${apiPrefix}/users/current_user`);
    return response.data;
  },

  async phoneLogin(payload) {
    const response = await api.post(`${apiPrefix}/${authPrefix}/phone`, payload);

    return response.data;
  },

  async requestOTP(payload) {
    const response = await api.post(`${apiPrefix}/${authPrefix}/request_otp`, payload);
    return response.data;
  },

  async signUp(payload) {
    const response = await api.post(`${apiPrefix}/sign_up`, payload);
    return response.data;
  },

  async forgotPassword(email) {
    const response = await api.post(`${apiPrefix}/${authPrefix}/forgot`, {
      email,
    });
    return response.data;
  },

  async updatePassword(payload) {
    const response = await api.put(`${apiPrefix}/users/reset_password`, {
      user: { ...payload },
    });
    return response.data;
  },

  async signOut() {
    const response = await api.delete(`${apiPrefix}/${authPrefix}/sign_out`);
    return response.data;
  },
};

export default AuthAPI;
