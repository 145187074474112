import { api } from "boot/axios";
import { format } from "date-fns";

const apiPrefix = "api/v2";

const ZammitUpdatesAPI = {
  async getZammitUpdates() {
    const response = await api.get(`${apiPrefix}/zammit_updates`);
    return response.data;
  },
};

export default ZammitUpdatesAPI;
