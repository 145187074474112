import VariantAPI from "src/api/Variant";
import { convertFromCentsByKeys, convertToCentsByKeys } from "src/utils";
// append = true; adds to the variants list, does not reset it
// append = false; resets the variants list
export async function getVariants({ commit }, { payload, append }) {
  if (!append) commit("updateInventoryLoading", true);
  const pagination = {
    page: payload.page || 1,
    limit: payload.limit || 25,
    order: payload.order || { id: "desc" },
  };
  const query = {
    searchQuery: payload.searchQuery || null,
  };

  try {
    let result = await VariantAPI.getVariants(query, pagination);
    if (append) commit("addVariants", result);
    else commit("updateVariantsList", result);
  } finally {
    if (!append) commit("updateInventoryLoading", false);
  }
}

export async function updateVariantQuantity({ commit }, { id, data }) {
  commit("updateVariantsLoading", { id, loading: true });

  try {
    const result = await VariantAPI.updateVariant(id, {
      quantity: data.quantity,
    });
    commit("updateVariant", result);
  } finally {
    commit("updateVariantsLoading", { id, loading: false });
  }
}

export async function updateVariantPrice({ commit }, { id, data }) {
  commit("updateVariantsLoading", { id, loading: true });
  try {
    const result = await VariantAPI.updateVariant(id, {
      ...convertToCentsByKeys(data, ["price", "discountedPrice"]),
      isOnSale: data.isOnSale,
    });
    commit("updateVariant", result);
  } finally {
    commit("updateVariantsLoading", { id, loading: false });
  }
}

export async function exportVariants({ commit, state, rootState }) {
  commit("updateExportLoading", true);
  try {
    const storeName = rootState.auth.company?.name || "Zammit";
    const date = new Date().toDateString().replaceAll(" ", "-");
    await VariantAPI.exportVariants({
      name: `${storeName}-variants-${date}`,
      limit: state.inventory.metadata.totalCount,
    });
  } finally {
    commit("updateExportLoading", false);
  }
}

export async function importVariants({ commit }, file) {
  commit("updateImportLoading", true);
  try {
    await VariantAPI.importVariants(file);
  } finally {
    commit("updateImportLoading", false);
  }
}
