import { getCustomerPhoneString } from "src/utils";

export const formatCustomer = (customer) => {
  return {
    isGuest: true,
    email: customer.basicInfo.email,
    phoneNumber: getCustomerPhoneString(customer.basicInfo.phone),
    name: `${customer.basicInfo.firstName} ${customer.basicInfo.lastName}`.trim(),
  };
};

export const formatCustomerAddress = (customer, addressInfo) => {
  return {
    address: {
      name: customer.name,
      addressableType: "Customer",
      addressableId: customer.id,
      phoneNumber: customer.phoneNumber,
      addressLine1: addressInfo.line,
      city: addressInfo.city.name,
      region: addressInfo.zone.name,
      country: addressInfo.country.name,
      building: addressInfo.building,
      floor: addressInfo.floor,
      apartment: addressInfo.apartment,
      locationId: addressInfo.locationId,
    },
  };
};
