export default {
  title: "We've got you",
  subtitle: "We make life easier for you by offering you the best in the market",
  choose_favourite: "Choose your favourite",
  contact: "Contact",
  offer: "Offer",
  apply: "Apply now",
  errors: {
    category_error: "Error in fetching Zammit Partner Categories",
    partner_error: "Error in fetching Zammit Partners",
  },
};
