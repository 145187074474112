export function updateCurrentDiscountsLoading(state, response) {
  state.currentDiscounts.loading = response;
}

export function updateCurrentDiscountsList(state, response) {
  state.currentDiscounts.data = response.data;
  state.currentDiscounts.metadata = response.metadata;
}

export function updateMetadata(state, metadata) {
  state.metadata = { ...state.metadata, ...metadata };
}

export function updateMetadataLoadingState(state, isLoading) {
  state.metadata = {
    ...state.metadata,
    loading: isLoading,
  };
}
export function addDiscounts(state, response) {
  state.currentDiscounts.data.push(...response.data);
  state.currentDiscounts.metadata = response.metadata;
}

export function updateAddDiscountLoading(state, isLoading) {
  state.newDiscount.loading = isLoading;
}

export function updateDiscountLoading(state, isLoading) {
  state.discountDetails.loading = isLoading;
}

export function updateDiscountProducts(state, products) {
  state.discountDetails.products = products;
}

export function updateDiscountDetails(state, discount) {
  state.discountDetails.discount = discount;
}

export function updateDiscountStatus(state, discount) {
  state.discountDetails.discount = {
    ...state.discountDetails.discount,
    status: discount.status,
  };
}

export function resetDiscountDetails(state) {
  state.discountDetails.discount = {
    discount: null,
    products: [],
    loading: false,
    productsLoading: false,
  };
}
