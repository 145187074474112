export const logInUser = (state, payload) => {
  state.authToken = payload.token;
  state.user = payload.user;
};

export const signOut = (state) => {
  state.authToken = null;
  state.user = null;
  state.company = null;
  state.method = "phone";
  state.email = null;
  state.phone = null;
};

export const updateCompany = (state, payload) => {
  state.company = {
    ...state.company,
    ...payload,
  };
  state.user.companyId = payload.id;
};

export const updateUser = (state, payload) => {
  state.user = {
    ...state.user,
    ...payload,
  };
};

export function updateEmail(state, email) {
  state.email = email;
}

export function updatePhone(state, phone) {
  state.phone = phone;
}

export function updateMethod(state, method) {
  state.method = method;
}

export function updateForgotPasswordEmail(state, email) {
  state.forgotPassword = {
    ...state.forgotPassword,
    email,
  };
}

export function updateForgotPasswordLoading(state, loading) {
  state.forgotPassword = {
    ...state.forgotPassword,
    loading,
  };
}

export function updateOtpRequestLoading(state, isLoading) {
  state.otpRequest = {
    ...state.otpRequest,
    loading: isLoading,
  };
}

export function updateSignUpRequestLoading(state, isLoading) {
  state.signUpRequest = {
    ...state.signUpRequest,
    loading: isLoading,
  };
}

export function updateSignInRequestLoading(state, isLoading) {
  state.signInRequest = {
    ...state.signInRequest,
    loading: isLoading,
  };
}

export function updateHasAccountRequestLoading(state, isLoading) {
  state.hasAccountRequest = {
    ...state.hasAccountRequest,
    loading: isLoading,
  };
}

export function updateEditInfoRequestLoading(state, isLoading) {
  state.editInfoRequest = {
    ...state.editInfoRequest,
    loading: isLoading,
  };
}

export function updateOtpRequestError(state, error) {
  state.otpRequest = {
    ...state.otpRequest,
    error: error,
  };
}

export function updateSignUpRequestError(state, error) {
  state.signUpRequest = {
    ...state.signUpRequest,
    error: error,
  };
}

export function updateSignInRequestError(state, error) {
  state.signInRequest = {
    ...state.signInRequest,
    error: error,
  };
}

export function updateHasAccountRequestError(state, error) {
  state.hasAccountRequest = {
    ...state.hasAccountRequest,
    error: error,
  };
}

export function updateEditInfoRequestError(state, error) {
  state.editInfoRequest = {
    ...state.editInfoRequest,
    error: error,
  };
}

export function updateCompanyLoading(state, loading) {
  state.companyLoading = loading;
}
