export function updateList(state, payload) {
  state.list = payload;
}

export function updateCurrent(state, payload) {
  state.current = payload;
}

export function updateListLoading(state, payload) {
  state.listLoading = payload;
}

export function updateSaveLoading(state, payload) {
  state.saveLoading = payload;
}
