import { boot } from "quasar/wrappers";
import { createI18n } from "vue-i18n/index";
import messages from "src/i18n";

const i18n = createI18n({
  locale: "en",
  messages,
  missingWarn: false,
  silentFallbackWarn: true, // suppress not found keys warnings in dev mode
  //  TODO: better shared error handling logic across project
  // 1- Only use the “error” key — remove all uses of “errors”
  // 2- Refactor ZamBannerErrors
  // 3- Do not expect a translation key from the BE — The BE will send the actual text of the error
  // 4- Configure fallback errors from FE
  missing: (_, key) => {
    if (key.includes("settings.integration_tabs")) return ""; // return empty string if no translation is found
  },
});

export default boot(({ app }) => {
  app.use(i18n);
});

export function setI18nLocale(locale) {
  // If locale is one of available locales, set it
  if (["en", "ar"].includes(locale)) {
    i18n.global.locale = locale;
    // Set i18n instance on app
  } else {
    i18n.global.locale = "en";
  }
}

export { i18n };
