import { api } from "boot/axios";
import { formatPlans } from "./serializers/planSerializer";

const apiPrefix = "api/v2";
const planPrefix = "plans";
const subscriptionPrefix = "subscriptions";

const PlanAPI = {
  async getPlans() {
    const response = await api.get(`${apiPrefix}/${planPrefix}`);
    return formatPlans(response.data.data);
  },

  async getCustomerToken() {
    const response = await api.get(`${apiPrefix}/${subscriptionPrefix}/token`);
    return response.data.data.value;
  },
};

export default PlanAPI;
