export default function () {
  return {
    metadata: {
      loading: false,
      error: null,
      types: [],
      vendors: [],
      tags: [],
      collections: [],
      statuses: [],
    },
    inventory: {
      loading: false,
      variantsList: [],
      metadata: {
        totalCount: 0,
        totalPages: 0,
      },
    },

    exportLoading: false,
    importLoading: false,
  };
}
