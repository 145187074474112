import { convertFromCentsByKeys, convertToCentsByKeys, flattenObject, localizeObject } from "src/utils";

export const formatCollections = (collections) => {
  return collections.map((collection) => {
    return {
      id: collection.id,
      ...localizeObject(collection, "name", ""),
    };
  });
};

export const flattenPricing = (pricing) => {
  return {
    pricedBy: pricing.pricedBy,
    pricingUnit: pricing.pricingUnit,
    isOnSale: pricing.isOnSale,
    isVat: pricing.isVat,
    ...convertToCentsByKeys(pricing, ["price", "discountedPrice"]),
  };
};

export const formatExtra = (extra) => {
  return {
    ...extra,
    ...localizeObject(extra, "name", ""),
    options: extra.options.map((option) => {
      return {
        ...option,
        ...localizeObject(option, "name", ""),
        ...convertFromCentsByKeys(option, ["price"]),
        extraFees: option.priceCents !== 0,
      };
    }),
  };
};

export const flattenExtra = (extra) => {
  return {
    ...extra,
    ...flattenObject(extra, "name", extra.type),
    options: extra.options.map((option) => {
      return {
        ...option,
        ...(extra.type === "dropdown" && flattenObject(option, "name", extra.type)),
        ...convertToCentsByKeys(option, ["price"]),
      };
    }),
  };
};

export const getChannelParams = (chart, channel) => {
  /*
   total sales graph is an sql query so we can't use the same structure as other graphs,
   instead we pass channel to query params if we want a specific channel.
  */
  if (chart === "classifiedSales") {
    return { channel };
  } else {
    return {
      extra_conditions: JSON.stringify([
        {
          connector: "and",
          operator: "in",
          parameter: "channel",
          value: [channel],
        },
      ]),
    };
  }
};
