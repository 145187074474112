import { Store } from "src/store";

const gtagEvent = (event, data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    user: Store.state.auth.user,
    company: Store.state.auth.company,
    ...data,
  });
};

export const registerEvent = (event, data) => {
  if (!event) return;

  gtagEvent(event, data);
};
