import plansMap from "src/pages/Plan/utils/plansMap";

// Adds the proper key to each plan
// Temp solution until BE completes implementation
// Check plansMap for the mapping done
export const formatPlans = (plans) => {
  return plans.map((plan) => {
    return {
      ...plan,
      code: plansMap[plan.id],
    };
  });
};
