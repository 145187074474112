import ZammitUpdatesAPI from "src/api/ZammitUpdates";

export async function getZammitUpdates({ commit }) {
  commit("setZammitUpdatesLoading", true);
  try {
    let result = await ZammitUpdatesAPI.getZammitUpdates();
    commit("addZammitUpdates", result.data);
  } finally {
    commit("setZammitUpdatesLoading", false);
  }
}
