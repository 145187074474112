export default {
  id: {
    type: "text",
    inputmode: "text",
    operators: [],
    hidden: true,
  },
  name: {
    type: "text",
    inputmode: "text",
    operators: ["matches", "does_not_match"],
  },
  type: { type: "text", inputmode: "text", operators: ["eq", "not_eq"] },
  vendor: { type: "text", inputmode: "text", operators: ["eq", "not_eq"] },
  price_cents: {
    type: "number",
    inputmode: "decimal",
    operators: ["eq", "not_eq", "gt", "lt"],
    isCurrency: true,
  },
  discounted_price_cents: {
    type: "number",
    inputmode: "decimal",
    operators: ["eq", "not_eq", "gt", "lt"],
    isCurrency: true,
  },
  tags: { type: "text", inputmode: "text", operators: ["any"] },
  quantity: {
    type: "number",
    inputmode: "numeric",
    operators: ["eq", "gt", "lt"],
  },
  is_on_sale: {
    componentType: "dropdown",
    inputmode: "dropdown",
    operators: ["eq"],
    options: ["true", "false"],
  },
};

export const percentageOperators = ["matches", "does_not_match"];
