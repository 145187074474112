export function addVariants(state, response) {
  state.inventory.variantsList.push(
    ...response.variants.map((variant) => {
      return {
        ...variant,
        loading: false,
      };
    })
  );
  state.inventory.metadata = response.metadata;
}

export function updateInventoryLoading(state, isLoading) {
  state.inventory = {
    ...state.inventory,
    loading: isLoading,
  };
}

export function updateVariantsList(state, response) {
  state.inventory.variantsList = response.variants.map((variant) => {
    return {
      ...variant,
      loading: false,
    };
  });
  state.inventory.metadata = response.metadata;
}

export function updateVariantsLoading(state, { id, loading }) {
  const index = state.inventory.variantsList.findIndex((variant) => variant.id === id);
  if (index !== -1) state.inventory.variantsList[index].loading = loading;
}

export function updateVariant(state, payload) {
  const index = state.inventory.variantsList.findIndex((variant) => variant.id === payload.id);
  if (index !== -1) state.inventory.variantsList[index] = payload;
}

export function updateExportLoading(state, loading) {
  state.exportLoading = loading;
}

export function updateImportLoading(state, loading) {
  state.importLoading = loading;
}
