import { api } from "boot/axios";

const apiPrefix = "api/v2";
const locationsPrefix = "locations";

const LocationAPI = {
  async getLocations({ locationType, parentId }) {
    const response = await api.get(`${apiPrefix}/${locationsPrefix}`, {
      params: {
        location_type: locationType,
        parent_id: parentId,
      },
    });

    return response.data.data;
  },
};

export default LocationAPI;
